import type * as React from 'react';
import { Button, type ButtonProps } from '@/components/ui/button';
import { DownloadIcon } from '@radix-ui/react-icons';
import { trpc } from '@/lib/providers/trpc';
import { useTranslation } from 'react-i18next';

interface GeneratePdfProps extends Omit<ButtonProps, 'onClick'> {
	children: React.ReactNode;
	fileName?: string;
	buttonText?: string;
}

export function GeneratePdf({ children, fileName = 'export.pdf', buttonText, ...props }: GeneratePdfProps) {
	const { t } = useTranslation();

	const pdfMutation = trpc.renderPdf.useMutation({
		onSuccess(data) {
			const link = document.createElement('a');
			link.href = data.pdf;
			link.download = fileName;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
	});

	const path = location.pathname + location.search;

	return (
		<Button onClick={() => pdfMutation.mutate({ path })} isLoading={pdfMutation.isLoading} {...props}>
			{!pdfMutation.isLoading && <DownloadIcon className="size-4" />}
			{buttonText ?? t('export_pdf')}
			<div id='report' className='hidden print:block'>{children}</div>
		</Button>
	);
}
