import { LinkButton } from '@/components/buttons/link-button';
import { MutationActionsMenu, MutationDeleteAction, MutationEditAction } from '@/components/dropdown-menus/mutation-actions-menu';
import { Page, PageActions, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';
import { ServerDataTable } from '@/components/tables/server-data-table';
import { Button } from '@/components/ui/button';
import { DataTableLink } from '@/components/ui/data-table-link';
import { RelativeDate } from '@/components/ui/relative-date';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useSupplier, useSupplierIdSafe } from '@/hooks/useSupplier';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { trpc } from '@/lib/providers/trpc';
import { cn, downloadBase64File } from '@/lib/utils';
import { DownloadIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export function SupplierDocumentsPage() {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();
	const supplierId = useSupplierIdSafe();
	const supplier = useSupplier();
	const deleteDocument = trpc.tenantSupplierDocumentsDeleteById.useMutation();

	const download = trpc.tenantSupplierDocumentsDownloadFileById.useMutation({
		onSuccess(data) {
			downloadBase64File({
				base64: data.file.content,
				fileName: data.file.name,
				fileType: data.file.type,
			});
		},
	});

	return (
		<Page>
			<PageToolbar>
				<PageTitle>{t('supplier_documents')}</PageTitle>
				<PageActions>
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger type="button">
								<LinkButton disabled={supplier.data?.isArchived} to="./create">
									{t('create_document')}
								</LinkButton>
							</TooltipTrigger>
							{supplier.data?.isArchived && <TooltipContent>{t('disallowed_due_to_archived_supplier')}</TooltipContent>}
						</Tooltip>
					</TooltipProvider>
				</PageActions>
			</PageToolbar>
			<PageContent>
				<ServerDataTable
					columnsStorageKey="supplier-documents-page"
					query={trpc.tenantSupplierDocumentsList}
					params={{ tenantId, supplierId }}
					columns={[
						{
							id: 'name',
							title: t('name'),
							enableSorting: true,
							render: (row) => <DataTableLink to={`./${row.id}/edit`}>{row.name}</DataTableLink>,
						},
						{
							id: 'fileName',
							title: t('file'),
							enableSorting: true,
							render: (row) => {
								return (
									<Button
										type="button"
										variant="link"
										className="p-0"
										isLoading={download.isLoading && download.variables?.id === row.id}
										onClick={() =>
											download.mutate({
												id: row.id,
												tenantId,
											})
										}
									>
										<DownloadIcon className="size-4" />
										{row.fileName}
									</Button>
								);
							},
						},
						{
							id: 'documentCategoryName',
							title: t('document_category'),
							enableSorting: true,
						},
						{
							id: 'expiresAt',
							title: t('expires_at'),
							enableSorting: true,
							render: (row) => {
								if (!row.expiresAt) return null;

								return <RelativeDate className={cn(new Date(row.expiresAt) < new Date() && 'text-destructive')} date={row.expiresAt} />;
							},
						},
						{ id: 'uploadedByName', title: t('uploaded_by'), enableSorting: true },
						{ id: 'createdAt', title: t('uploaded_at'), enableSorting: true, render: (row) => <RelativeDate date={row.createdAt} /> },
						{
							id: 'id',
							title: t('actions'),
							actions: true,
							enableSorting: false,
							size: 40,
							render: (row) => {
								return (
									<MutationActionsMenu>
										<MutationEditAction to={`./${row.id}/edit`} />
										<MutationDeleteAction name={row.name} onConfirmDelete={() => deleteDocument.mutate({ id: row.id, tenantId })} />
									</MutationActionsMenu>
								);
							},
						},
					]}
					defaultSortColumn="createdAt"
					defaultSortOrder="desc"
					searchColumns={['name', 'documentCategoryName', 'fileName', 'fileType']}
					paginationPageSize={50}
				></ServerDataTable>
			</PageContent>
		</Page>
	);
}
