import { UnsavedChangesBlocker } from '@/components/dialogs/unsaved-changes-blocker';
import { FormBuilder } from '@/components/form-builders/form-builder';
import { Page, PageTitle, PageToolbar, PageContent } from '@/components/layouts/page';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormDescription } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';
import { trpc } from '@/lib/providers/trpc';
import { zodResolver } from '@hookform/resolvers/zod';
import { formTemplatesCommonInsertSchemaInput } from '@timp/server/src/schemas/form-templates-common.schema';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { LinkButton } from '@/components/buttons/link-button';

export function TenantSettingsCaseFormsEditPage() {
	const { t } = useTranslation();
	const { id } = useParams();
	const navigate = useNavigate();
	const tenantId = useTenantIdSafe();
	const [dialogOpen, setDialogOpen] = useState(false);

	const { data, isLoading } = trpc.tenantFormTemplatesById.useQuery({
		tenantId,
		id: id!,
	});

	const updateMutation = trpc.tenantFormTemplatesUpdateById.useMutation({
		onSuccess() {
			form.reset(form.getValues());
			navigate('../');
		},
	});

	const form = useForm({
		resolver: zodResolver(formTemplatesCommonInsertSchemaInput),
		values: data,
	});

	const hasUnsavedChanges = !!form.formState.dirtyFields.formContent;

	return (
		<Page isLoading={isLoading} className="overflow-x-auto overflow-y-hidden">
			<Form {...form}>
				<PageToolbar>
					<PageTitle backLink>{t('edit_case_form')}</PageTitle>
				</PageToolbar>

				<PageContent>
					<div className="space-y-6">
						<FormField
							control={form.control}
							name="name"
							render={({ field }) => (
								<FormItem>
									<FormLabel required={true}>{t('form_name')}</FormLabel>
									<FormControl>
										<Input {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="formContent"
							render={() => (
								<FormItem>
									<FormLabel required={true}>{t('form_content')}</FormLabel>
									<FormControl>
										<div className="flex items-center gap-x-2">
											<Button
												variant="outline"
												type="button"
												onClick={() => {
													setDialogOpen(true);
												}}
											>
												{t('edit_form')}
											</Button>
										</div>
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="published"
							render={({ field }) => (
								<FormItem className="flex flex-col">
									<div className="flex items-center gap-x-2">
										<FormControl>
											<Checkbox checked={field.value} onCheckedChange={field.onChange} name={field.name} />
										</FormControl>
										<FormLabel required={false}>{t('published')}</FormLabel>
									</div>
									<FormDescription>{t('form_published_description')}</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<div className="flex justify-end gap-4">
							<LinkButton variant="outline" to="../">
								{t('cancel')}
							</LinkButton>

							<Button
								isLoading={updateMutation.isLoading}
								type="button"
								onClick={() =>
									form.handleSubmit(
										(formData) => {
											updateMutation.mutate({ ...formData, tenantId, id: id! });
										},
										(errors) => {
											if (Object.keys(errors.formContent ?? {}).length > 0) {
												setDialogOpen(true);
											}
										},
									)()
								}
							>
								{t('save_changes')}
							</Button>
						</div>
					</div>
					<Dialog
						open={dialogOpen}
						onOpenChange={(open) => {
							setDialogOpen(open);
						}}
					>
						<DialogContent size="full">
							<FormBuilder />
						</DialogContent>
					</Dialog>
				</PageContent>

				<UnsavedChangesBlocker hasUnsavedChanges={hasUnsavedChanges && updateMutation.isIdle} />
			</Form>
		</Page>
	);
}
