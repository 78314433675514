import { FieldValues, UseFormReturn } from 'react-hook-form';
import { FormControl, FormDescription, FormField, FormItem, FormLabel } from '../ui/form';
import { Input } from '../ui/input';
import { cn, narrowForm } from '@/lib/utils';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { Button } from '../ui/button';
import { CalendarIcon } from 'lucide-react';
import { Calendar } from '../ui/calendar';
import { useDateFns } from '@/hooks/useDateFns';
import { useTranslation } from 'react-i18next';
import { add, endOfDay, min, startOfDay } from 'date-fns';
import { useEffect } from 'react';

type GenericTimeInterval = {
	interval: {
		amount: number;
		type: 'days' | 'months' | 'years';
	};
	startSendoutAt?: string;
	stopSendoutAt?: string | null | undefined;
};
interface Props<T extends FieldValues> {
	form: UseFormReturn<T & GenericTimeInterval>;
}

export function PartialFormAutomaticCaseTimeScheduler<T extends FieldValues>({ form }: Props<T>) {
	const { format } = useDateFns();
	const { t } = useTranslation();

	const { control, watch, setValue } = narrowForm<GenericTimeInterval>(form);
	const minStartSendoutAt = min([startOfDay(Date.now()), new Date(watch('startSendoutAt')!)]);
	const intervalAmount = watch('interval.amount');
	const intervalType = watch('interval.type');

	const minStopSendoutAt = add(minStartSendoutAt, {
		[intervalType]: intervalAmount,
	});

	// Reset stopSendoutAt if it's less than minStopSendoutAt
	useEffect(() => {
		const currentMax = watch('stopSendoutAt');
		if (currentMax && minStopSendoutAt > new Date(currentMax)) {
			setValue('stopSendoutAt', undefined);
		}
	}, [minStopSendoutAt, watch, setValue]);

	return (
		<div className="flex flex-col">
			<div className="flex flex-wrap items-end gap-12 rounded-md border border-border bg-muted px-4 py-6">
				<div className="flex flex-col">
					<div className="flex items-end">
						<FormField
							control={control}
							name="interval.amount"
							render={({ field, fieldState }) => (
								<FormItem>
									<FormLabel required>{t('interval')}</FormLabel>
									<FormControl>
										<Input
											{...field}
											onChange={(ev) => {
												// integers only
												const value = ev.target.value.replace(/[^\d]/g, '');
												field.onChange(parseInt(value, 10) || null);
											}}
											type="number"
											className={cn('rounded-none rounded-l-md bg-background', fieldState.error && 'border-destructive focus-visible:border-destructive')}
										/>
									</FormControl>
									<FormDescription>{t('how_often_should_the_cases_be_sent_out')}</FormDescription>
								</FormItem>
							)}
						/>
						<FormField
							control={control}
							name="interval.type"
							render={({ field }) => (
								<FormItem>
									<Select onValueChange={field.onChange} value={field.value}>
										<FormControl>
											<SelectTrigger className="min-w-24 rounded-none rounded-r-md border-l-transparent bg-background">
												<SelectValue />
											</SelectTrigger>
										</FormControl>
										<SelectContent>
											<SelectItem value="days">{`${t('days')}`}</SelectItem>
											<SelectItem value="months">{`${t('months')}`}</SelectItem>
											<SelectItem value="years">{`${t('years')}`}</SelectItem>
										</SelectContent>
									</Select>
									{/* Must match the amount of rows, so we'll use a blank character */}
									<FormDescription>&nbsp;</FormDescription>
								</FormItem>
							)}
						/>
					</div>
				</div>

				<FormField
					control={control}
					name="startSendoutAt"
					render={({ field, fieldState }) => (
						<FormItem className="flex flex-col">
							<FormLabel required>{t('start_sendout_at')}</FormLabel>
							<Popover>
								<PopoverTrigger asChild>
									<FormControl>
										<Button
											variant={'outline'}
											className={cn('pl-3 text-left font-normal', !field.value && 'text-muted-foreground', fieldState.error && 'border-destructive')}
										>
											{field.value ? format(new Date(field.value), 'PPP') : <span>{t('pick_a_date')}</span>}
											<CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
										</Button>
									</FormControl>
								</PopoverTrigger>
								<PopoverContent className="w-auto p-0" align="start">
									<Calendar
										mode="single"
										selected={new Date(field.value!)}
										defaultMonth={new Date(field.value!)}
										onSelect={(date) => (date ? field.onChange(startOfDay(date).toISOString()) : null)}
										initialFocus
										fromDate={minStartSendoutAt}
									/>
								</PopoverContent>
							</Popover>
							<FormDescription>{t('when_should_the_system_start_sending_cases')}</FormDescription>
						</FormItem>
					)}
				/>

				<FormField
					control={control}
					name="stopSendoutAt"
					render={({ field, fieldState }) => (
						<FormItem className="flex flex-col">
							<FormLabel required={false}>{t('stop_sendout_at')}</FormLabel>
							<Popover>
								<PopoverTrigger asChild>
									<FormControl>
										<Button
											variant={'outline'}
											className={cn('pl-3 text-left font-normal', !field.value && 'text-muted-foreground', fieldState.error && 'border-destructive')}
										>
											{field.value ? (
												format(new Date(field.value), 'PPP')
											) : (
												<span>
													{t('pick_a_date')}
													<span className="lowercase"> ({t('optional')})</span>
												</span>
											)}
											<CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
										</Button>
									</FormControl>
								</PopoverTrigger>
								<PopoverContent className="w-auto p-0" align="start">
									<Calendar
										mode="single"
										selected={field.value ? new Date(field.value) : undefined}
										defaultMonth={new Date(field.value || minStopSendoutAt)}
										onSelect={(date) => (date ? field.onChange(endOfDay(date).toISOString()) : null)}
										fromDate={minStopSendoutAt}
										initialFocus
									/>
									<Button type="button" className="w-full" variant="outline" onClick={() => field.onChange(null)}>
										{t('clear')}
									</Button>
								</PopoverContent>
							</Popover>
							<FormDescription>{t('when_should_the_system_stop_sending_cases')}</FormDescription>
						</FormItem>
					)}
				/>
			</div>
		</div>
	);
}
