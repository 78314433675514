import { zodStringOrNull } from '../utils/zod-extensions';
import * as z from 'zod';

export const tenantSuppliersInsertManyInputSchema = z.object({
	suppliers: z.array(
		z.object({
			name: z.string().min(1).max(250),
			organizationNumber: z.string().min(1).max(250),
			internalId: z.string().min(1).max(250).optional(),
			contactFullName: z.string().min(1).max(250).trim(),
			contactEmail: z.string().email().toLowerCase().trim(),
			contactPhone: zodStringOrNull({ max: 50 }),
			contactRole: zodStringOrNull({ max: 250 }),
			status: z.enum(['CAN_USE', 'SHOULD_NOT_BE_USED', 'OK']),
			departments: z
				.array(
					z.object({
						id: z.string().uuid(),
						name: z.string().min(1).max(250),
					}),
				)
				.optional(),
			groups: z
				.array(
					z.object({
						id: z.string().uuid(),
						name: z.string().min(1).max(250),
					}),
				)
				.optional(),
			projects: z
				.array(
					z.object({
						id: z.string().uuid(),
						name: z.string().min(1).max(250),
					}),
				)
				.optional(),
		}),
	),
});

export const tenantSuppliersInsertManyOutputSchema = z.array(z.string().uuid());
