import * as z from 'zod';

export const sysadminUsersUpdateByIdInputSchema = z.object({
	userId: z.string().uuid(),
	isSysAdmin: z.boolean().optional(),
	firstName: z.string().min(1).max(250).trim().optional(),
	lastName: z.string().min(1).max(250).trim().optional(),
	email: z.string().email().toLowerCase().trim().optional(),
});

export const sysadminUsersUpdateByIdOutputSchema = z.object({
	userId: z.string().uuid(),
});
