import { Dialog, DialogContent, DialogTitle, DialogHeader } from '@/components/ui/dialog';
import { TypographyLabel } from '@/components/ui/typography';
import { Button } from '@/components/ui/button';
import { QRCodeSVG } from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { CopyIcon, DownloadIcon, SquareArrowOutUpRight } from 'lucide-react';
import { renderToString } from 'react-dom/server';
import { useForm } from 'react-hook-form';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { trpc } from '@/lib/providers/trpc';
import type { RouterOutput } from '@/lib/providers/trpc';
import { SelectSingleEquipment } from '@/components/selects/select-single';
import { Form, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { SelectSingleDepartment, SelectSingleLocation, SelectSingleProject, SelectSingleSupplier } from '@/components/selects/select-single';
import { Page } from '@/components/layouts/page';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { toast } from 'sonner';

interface QRPreviewProps {
	formTemplateId: string;
	formName: string;
	onClick: () => void;
}
interface InspectionUrlArgs {
	formTemplateId: string;
	tenantId: string;
	supplierId?: string;
	departmentId?: string;
	projectId?: string;
	equipmentId?: string;
	locationId?: string;
}
function getInspectionFormUrl({ formTemplateId, tenantId, supplierId, departmentId, projectId, equipmentId, locationId }: InspectionUrlArgs) {
	const url = new URL(`${window.location.origin}/inspector/tenants/${tenantId}/forms/${formTemplateId}`);

	if (supplierId) url.searchParams.set('supplierId', supplierId);
	if (departmentId) url.searchParams.set('departmentId', departmentId);
	if (projectId) url.searchParams.set('projectId', projectId);
	if (equipmentId) url.searchParams.set('equipmentId', equipmentId);
	if (locationId) url.searchParams.set('locationId', locationId);

	return url.toString();
}

export function QRPreview({ formTemplateId, formName, onClick }: QRPreviewProps) {
	const tenantId = useTenantIdSafe();
	const formUrl = getInspectionFormUrl({ formTemplateId, tenantId });

	return <QRCodeSVG data-testid={`${formName} qr-code`} className="cursor-pointer" value={formUrl} size={64} onClick={onClick} />;
}

interface QRDialogForm {
	formTemplateId: string;
	tenantId: string;
	supplierId?: string;
	departmentId?: string;
	projectId?: string;
	equipmentId?: string;
	locationId?: string;
}

interface QRCodeBuilderProps {
	formTemplateId: string;
	isOpen: boolean;
	onClose: () => void;
}
type InspectionFormTemplate = Extract<RouterOutput['tenantFormTemplatesById'], { type: 'INSPECTION' }>;

export function TenantInspectionFormsQRCodeBuilder({ formTemplateId, isOpen, onClose }: QRCodeBuilderProps) {
	const { t } = useTranslation();
	const [animateRef] = useAutoAnimate({
		duration: 150,
	});
	const tenantId = useTenantIdSafe();

	const form = useForm<QRDialogForm>({
		values: {
			formTemplateId,
			tenantId,
		},
	});

	const qrValue = form.watch();
	const formUrl = getInspectionFormUrl(qrValue);

	const {
		data: formTemplate,
		isLoading,
		error,
	} = trpc.tenantFormTemplatesById.useQuery(
		{
			id: formTemplateId,
			tenantId,
		},
		{
			enabled: !!formTemplateId,
			select: (data) => data as InspectionFormTemplate,
		},
	);

	function downloadQRCode() {
		if (!formTemplateId) return;

		const svgData = renderToString(<QRCodeSVG xmlns="http://www.w3.org/2000/svg" value={formUrl} size={512} />);
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d')!;
		const img = new Image();

		img.onload = () => {
			canvas.width = img.width;
			canvas.height = img.height;
			ctx.drawImage(img, 0, 0);
			const pngFile = canvas.toDataURL('image/png');
			const downloadLink = document.createElement('a');
			downloadLink.download = formTemplate?.name ?? 'QR';
			downloadLink.href = `${pngFile}`;
			downloadLink.click();
		};
		img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
	}

	const handleOpenChange = (open: boolean) => {
		if (!open) {
			form.reset();
			onClose();
		}
	};

	return (
		<Dialog open={isOpen} onOpenChange={handleOpenChange}>
			<DialogContent size="dynamic">
				<DialogHeader>
					<DialogTitle>
						{t('qr_code')}: {formTemplate?.name}
					</DialogTitle>
				</DialogHeader>
				<Form {...form}>
					<form className="flex flex-col justify-between gap-8 overflow-y-auto md:flex-row" onSubmit={(e) => e.preventDefault()}>
						<div className="flex min-w-96 flex-1 basis-1/2 flex-col">
							<Page error={error} isLoading={isLoading} className="scroll py-0 md:py-0">
								{formTemplate && (
									<>
										{formTemplate.enableSuppliers !== 'DISABLED' && (
											<FormField
												control={form.control}
												name="supplierId"
												render={({ field }) => (
													<FormItem>
														<FormLabel required={false}> {t('supplier')}</FormLabel>
														<SelectSingleSupplier value={field.value} onChange={field.onChange} />
														<FormMessage />
													</FormItem>
												)}
											/>
										)}

										{formTemplate.enableDepartments !== 'DISABLED' && (
											<FormField
												control={form.control}
												name="departmentId"
												render={({ field }) => (
													<FormItem>
														<FormLabel required={false}> {t('department')}</FormLabel>
														<SelectSingleDepartment value={field.value} onChange={field.onChange} />
														<FormMessage />
													</FormItem>
												)}
											/>
										)}

										{formTemplate.enableProjects !== 'DISABLED' && (
											<FormField
												control={form.control}
												name="projectId"
												render={({ field }) => (
													<FormItem>
														<FormLabel required={false}> {t('project')}</FormLabel>
														<SelectSingleProject value={field.value} onChange={field.onChange} />
														<FormMessage />
													</FormItem>
												)}
											/>
										)}

										{formTemplate.enableLocations !== 'DISABLED' && (
											<FormField
												control={form.control}
												name="locationId"
												render={({ field }) => (
													<FormItem>
														<FormLabel required={false}> {t('location')}</FormLabel>
														<SelectSingleLocation value={field.value} onChange={field.onChange} />
														<FormMessage />
													</FormItem>
												)}
											/>
										)}

										{formTemplate.enableEquipment !== 'DISABLED' && (
											<FormField
												control={form.control}
												name="equipmentId"
												render={({ field }) => (
													<FormItem>
														<FormLabel required={false}> {t('equipment')}</FormLabel>
														<SelectSingleEquipment value={field.value} onChange={field.onChange} />
														<FormMessage />
													</FormItem>
												)}
											/>
										)}
									</>
								)}
							</Page>
						</div>
						<div className="flex flex-1 basis-1/2 flex-col items-center gap-y-4 md:gap-y-6">
							<div className="" ref={animateRef}>
								<QRCodeSVG value={formUrl} key={formUrl} className="h-auto w-64 md:w-72 lg:w-96" />
							</div>
							<div className="flex flex-wrap justify-center gap-x-1 md:flex-nowrap">
								<Button
									size="sm"
									variant="ghost"
									onClick={() => {
										window.open(formUrl, '_blank', 'noopener,noreferrer');
									}}
								>
									<SquareArrowOutUpRight />
									<TypographyLabel className="capitalize">{t('visit_link')}</TypographyLabel>
								</Button>
								<Button
									size="sm"
									variant="ghost"
									onClick={() => {
										navigator.clipboard.writeText(formUrl);
										toast.success(t('link_copied_to_clipboard'));
									}}
								>
									<CopyIcon />
									<TypographyLabel className="capitalize">{t('copy_link')}</TypographyLabel>
								</Button>
								<Button size="sm" variant="ghost" onClick={downloadQRCode}>
									<DownloadIcon />
									<TypographyLabel className="capitalize">{t('download_qr')}</TypographyLabel>
								</Button>
							</div>
						</div>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
}
