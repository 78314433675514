import { Separator } from './separator';
import { Popover, PopoverTrigger, PopoverContent } from './popover';
import { Button } from './button';
import { Badge } from './badge';
import { Calendar } from './calendar';
import { useDateFns } from '@/hooks/useDateFns';
import { endOfDay } from 'date-fns';
import { SlidersHorizontalIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface Props {
	title?: string;
	minDate: string;
	maxDate: string;
	fromDate: string | null;
	toDate: string | null;
	onChange: (fromDate: string | null, toDate: string | null) => void;
	onOpenChange?: (open: boolean) => void;
	open?: boolean;
}
export function DataTableDateFilter({ title, minDate, maxDate, fromDate, toDate, onChange, onOpenChange, open }: Props) {
	const { t } = useTranslation();
	const { format } = useDateFns();
	return (
		<Popover open={open} onOpenChange={onOpenChange}>
			<PopoverTrigger asChild>
				<Button variant="outline" size="sm" className="h-8 border-dashed">
					<SlidersHorizontalIcon className="mr-2 h-4 w-4" />
					{title}
					{(fromDate || toDate) && (
						<>
							<Separator orientation="vertical" className="mx-2 h-4" />
							<div className="flex gap-x-1">
								<Badge variant="secondary" className="rounded-sm px-1 font-normal">
									{fromDate ? format(new Date(fromDate), 'dd.MM.yyyy') : '-'}
								</Badge>
								<Badge variant="secondary" className="rounded-sm px-1 font-normal">
									{toDate ? format(new Date(toDate), 'dd.MM.yyyy') : '-'}
								</Badge>
							</div>
						</>
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-auto p-0" align="start">
				<Calendar
					initialFocus
					mode="range"
					selected={fromDate ? { from: new Date(fromDate), to: toDate ? new Date(toDate) : undefined } : undefined}
					onSelect={(dateRange) => {
						const from = dateRange?.from?.toISOString() ?? null;
						const to = dateRange?.to ? endOfDay(dateRange.to).toISOString() : null;
						onChange(from, to);
					}}
					fromDate={new Date(minDate)}
					toDate={new Date(maxDate)}
					captionLayout="dropdown-buttons"
				/>
				{(fromDate || toDate) && (
					<>
						<Separator />
						<div className="flex w-full justify-center p-1">
							<Button
								onClick={() => onChange(null, null)}
								className="relative flex h-8 w-full cursor-default select-none items-center rounded-sm bg-transparent px-2 py-1.5 text-sm outline-none aria-selected:bg-accent aria-selected:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
								variant="secondary"
							>
								{t('reset')}
							</Button>
						</div>
					</>
				)}
			</PopoverContent>
		</Popover>
	);
}
