import { LinkButton } from '@/components/buttons/link-button';
import { CaseStatusIconLabel, CaseStatus } from '@/components/labels/case-status-icon-label';
import { StatusIconLabel } from '@/components/labels/status-icon-label';
import { Page, PageTitle, PageToolbar, PageActions, PageContent } from '@/components/layouts/page';
import { ServerDataTable } from '@/components/tables/server-data-table';
import { Badge } from '@/components/ui/badge';
import { DataTableLink } from '@/components/ui/data-table-link';
import { DateTooltip, DistanceToNowDate, RelativeDate } from '@/components/ui/relative-date';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { useRole } from '@/hooks/useUser';
import { trpc } from '@/lib/providers/trpc';
import { isPast } from 'date-fns';
import { useTranslation } from 'react-i18next';

export function CasesPage() {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();
	const role = useRole(tenantId);
	return (
		<Page>
			<PageToolbar>
				<PageTitle>{t('cases')}</PageTitle>

				<PageActions>
					<LinkButton variant="outline" to={'../settings/case-forms'}>
						{t('manage_form_templates')}
					</LinkButton>
					<LinkButton to="./create">{t('create_case')}</LinkButton>
				</PageActions>
			</PageToolbar>
			<PageContent>
				<ServerDataTable
					columnsStorageKey="tenant-cases-page"
					query={trpc.tenantCasesList}
					params={{
						tenantId,
					}}
					columns={[
						{
							id: 'formTemplateName',
							title: t('form_name'),
							filter: true,
							enableSorting: false,
							render(row) {
								return <DataTableLink to={`./${row.id}`}>{row.formTemplateName}</DataTableLink>;
							},
						},
						{
							id: 'title',
							title: t('title'),
							enableSorting: true,
						},
						{
							id: 'supplierName',
							title: t('supplier'),
							enableSorting: true,
							filter: true,
							render(row) {
								return <DataTableLink to={`../suppliers/${row.supplierId}/cases`}>{row.supplierName}</DataTableLink>;
							},
						},
						{
							id: 'status',
							title: t('status'),
							filter: true,

							enableSorting: false,
							render: (row) => <CaseStatusIconLabel status={row.status} />,
							valueToLabel: (status: CaseStatus) => t(`${status}`),
						},
						{
							id: 'deviationsCount',
							title: t('deviations'),
							render: (row) => {
								if (row.deviationsCount) {
									return <Badge variant="secondary">{t('deviations_count', { count: row.deviationsCount })}</Badge>;
								}
								return null;
							},
						},
						{
							id: 'processedAt',
							title: t('processed_at'),
							render: (row) => {
								if (!row.processedAt) return null;
								return <RelativeDate date={row.processedAt} />;
							},
							timeFilter: true,
							enableSorting: true,
						},
						{
							id: 'responseDeadline',
							title: t('response_deadline'),
							render: (row) => {
								if (row.status === 'PROCESSED' || row.status === 'ANSWERED') return null;
								if (isPast(new Date(row.responseDeadline))) {
									return <StatusIconLabel variant="error" label={<DateTooltip date={row.responseDeadline} label={t('expired')} />} />;
								}
								return <StatusIconLabel variant="waiting" label={<DistanceToNowDate date={row.responseDeadline} />} />;
							},
							enableSorting: true,
						},

						{
							id: 'lastActivityAt',
							title: t('last_activity_at'),
							render: (row) => <RelativeDate date={row.lastActivityAt} />,
							enableSorting: true,
						},
						{
							id: 'createdByName',
							title: t('created_by'),
							filter: true,
							enableSorting: false,
							render: (row) => {
								if (row.automaticCaseId && role === 'ADMIN') {
									return <DataTableLink to={`/tenants/${tenantId}/settings/automatic-cases/${row.automaticCaseId}/edit`}>{row.createdByName}</DataTableLink>;
								}
								return row.createdByName;
							},
						},
						{
							id: 'createdAt',
							title: t('created_at'),
							render: (row) => <RelativeDate date={row.createdAt} />,
							timeFilter: true,
							enableSorting: true,
						},
					]}
					defaultSortColumn="createdAt"
					defaultSortOrder="desc"
					paginationPageSize={50}
				/>
			</PageContent>
		</Page>
	);
}
