import { LinkButton } from '@/components/buttons/link-button';
import { DataTable } from '@/components/tables/data-table';
import { DataTableLink } from '@/components/ui/data-table-link';
import { MutationActionsMenu, MutationDeleteAction, MutationEditAction } from '@/components/dropdown-menus/mutation-actions-menu';
import { useDateFns } from '@/hooks/useDateFns';
import { trpc } from '@/lib/providers/trpc';
import { useTranslation } from 'react-i18next';
import { Page, PageActions, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';
import { Button } from '@/components/ui/button';
import { useState } from 'react';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { sysadminDemoTenantInsertInputSchema } from '@timp/server/src/schemas/sysadmin-demo-tenant-insert.schema';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Input } from '@/components/ui/input';
import { toast } from 'sonner';

export function SysAdminTenantsPage() {
	const { format } = useDateFns();
	const { data = [], isLoading, error } = trpc.tenantsList.useQuery();
	const { t } = useTranslation();
	const { mutate: deleteTenant } = trpc.tenantsDeleteById.useMutation();

	const [dialogOpen, setDialogOpen] = useState(false);

	const form = useForm<z.infer<typeof sysadminDemoTenantInsertInputSchema>>({
		resolver: zodResolver(sysadminDemoTenantInsertInputSchema),
	});
	const createDemoTenant = trpc.sysadminDemoTenantInsert.useMutation({
		onSuccess() {
			setDialogOpen(false);
			toast.success(t('demo_tenant_created'));
		},
	});

	return (
		<>
			<Dialog
				open={dialogOpen}
				onOpenChange={(val) => {
					if (!val) {
						setDialogOpen(false);
						form.reset();
					}
				}}
			>
				<form onSubmit={form.handleSubmit((data) => createDemoTenant.mutate(data))}>
					<DialogContent isLoading={isLoading} error={error} className="sm:max-w-[425px]">
						<DialogHeader>
							<DialogTitle>{t('create_demo_company')}</DialogTitle>
						</DialogHeader>
						<Form {...form}>
							<FormField
								control={form.control}
								name="name"
								render={({ field }) => (
									<FormItem>
										<FormLabel required>{t('tenant_name')}</FormLabel>
										<FormControl>
											<Input {...field} />
										</FormControl>
										<FormDescription>{t('tenant_name_description')}</FormDescription>
										<FormMessage />
									</FormItem>
								)}
							/>
						</Form>
						<DialogFooter>
							<Button
								type="button"
								variant="ghost"
								onClick={() => {
									setDialogOpen(false);
									form.reset();
								}}
							>
								{t('cancel')}
							</Button>
							<Button isLoading={createDemoTenant.isLoading} type="submit">
								{t('create')}
							</Button>
						</DialogFooter>
					</DialogContent>
				</form>
			</Dialog>
			<Page isLoading={isLoading} error={error}>
				<PageToolbar>
					<PageTitle>{t('tenants_title')}</PageTitle>
					<PageActions>
						<Button variant="ghost" onClick={() => setDialogOpen(true)}>
							{t('create_demo_company')}
						</Button>
						<LinkButton to="./create">{t('tenant_create')}</LinkButton>
					</PageActions>
				</PageToolbar>

				<PageContent>
					<DataTable
						columnsStorageKey="sys-admin-tenants-page"
						isLoading={isLoading}
						error={error}
						data={data}
						columns={[
							{
								id: 'name',
								title: t(`tenant_name`),
								render(row) {
									return <DataTableLink to={`/tenants/${row.id}/settings/edit`}>{row.name}</DataTableLink>;
								},
							},
							{
								id: 'language',
								title: t('language'),
								render(row) {
									switch (row.language) {
										case 'en':
											return t('language_english');
										case 'no':
											return t('language_norwegian');
										default:
											return row.language;
									}
								},
							},
							{
								id: 'isDemo',
								title: t('is_demo'),
								render(row) {
									return row.isDemo ? t('yes') : t('no');
								},
							},
							{
								id: 'createdAt',
								title: t('created_at'),
								render(row) {
									return format(new Date(row.createdAt), 'Pp');
								},
							},
							{
								id: 'id',
								title: t('actions'),
								actions: true,
								enableSorting: false,
								size: 40,
								render(row) {
									return (
										<MutationActionsMenu data-testid={row.name}>
											<MutationEditAction to={`/tenants/${row.id}/settings/edit`} />
											<MutationDeleteAction name={row.name} onConfirmDelete={() => deleteTenant({ id: row.id })} />
										</MutationActionsMenu>
									);
								},
							},
						]}
						searchColumn="name"
						defaultSortColumn="name"
					/>
				</PageContent>
			</Page>
		</>
	);
}
