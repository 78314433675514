import { Button } from '@/components/ui/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { tenantSuppliersUpdateByIdInputSchema } from '@timp/server/src/schemas/tenant-suppliers-update-by-id.schema';
import { useTranslation } from 'react-i18next';
import { trpc } from '@/lib/providers/trpc';
import { useNavigate } from 'react-router-dom';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { LinkButton } from '@/components/buttons/link-button';
import { Page, PageActions, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';
import { useSupplierIdSafe } from '@/hooks/useSupplier';
import {
	SelectMultipleAutomaticCases,
	SelectMultipleDepartments,
	SelectMultipleDocumentCategories,
	SelectMultipleGroups,
	SelectMultipleProjects,
} from '@/components/selects/select-multiple';
import { toast } from 'sonner';
import { AutoComplete } from '@/components/ui/autocomplete';
import { useBrregService } from '@/hooks/useBrregService';
import { useState } from 'react';
import { ArchiveRestoreIcon, ArchiveXIcon, Trash2Icon } from 'lucide-react';
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogDescription,
} from '@/components/ui/alert-dialog';
import { SelectRiskEvaluation } from '@/components/selects/select-risk-evaluation';

export function SupplierEditPage() {
	const supplierId = useSupplierIdSafe();
	const tenantId = useTenantIdSafe();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
	const [searchNameValue, setSearchNameValue] = useState<string>('');
	const searchNameQuery = useBrregService(searchNameValue, { keepPreviousData: true });
	const supplier = trpc.tenantSuppliersById.useQuery({ id: supplierId, tenantId });

	const { mutate, isLoading } = trpc.tenantSuppliersUpdateById.useMutation({
		onSuccess() {
			toast.success(t('supplier_update_success'));
			navigate(`../`);
		},
	});

	const readOnly = !!supplier.data?.isArchived;

	const form = useForm<z.infer<typeof tenantSuppliersUpdateByIdInputSchema>>({
		resolver: zodResolver(tenantSuppliersUpdateByIdInputSchema),
		values: supplier.data && {
			id: supplier.data.id,
			name: supplier.data.name,
			internalId: supplier.data.internalId ?? undefined,
			departments: supplier.data?.departments.map((department) => department.id),
			groups: supplier.data?.groups.map((group) => group.id),
			projects: supplier.data?.projects.map((project) => project.id),
			mandatoryDocumentCategories: supplier.data?.mandatoryDocumentCategories.map((doc) => doc.id),
			automaticCases: supplier.data?.automaticCases.map((doc) => doc.automaticCaseId),
			organizationNumber: supplier.data.organizationNumber,
			status: supplier.data.status,
			tenantId,
		},
		defaultValues: {
			departments: [],
			groups: [],
			projects: [],
			mandatoryDocumentCategories: [],
			automaticCases: [],
			status: 'OK',
		},
	});

	const groups = trpc.tenantGroupsList.useQuery({
		tenantId,
	});

	const departments = trpc.tenantDepartmentsList.useQuery({
		tenantId,
	});

	const projects = trpc.tenantProjectsList.useQuery({
		tenantId,
	});

	const organizationNumberControl = useController({
		name: 'organizationNumber',
		control: form.control,
	});

	const deleteMutation = trpc.tenantSuppliersDeleteById.useMutation({
		onSuccess() {
			navigate('../../', {
				unstable_flushSync: true,
			});
		},
	});
	const archiveToggleMutation = trpc.tenantSuppliersArchiveToggleById.useMutation({
		onSuccess(data) {
			if (!data.isArchived) {
				toast.success(t('supplier_unarchived_success'));
			}
		},
	});

	return (
		<Page isLoading={groups.isLoading || departments.isLoading || projects.isLoading || supplier.isLoading}>
			<PageToolbar>
				<PageTitle>{t('supplier')}</PageTitle>
				<PageActions>
					{supplier.data?.isArchived ? (
						<Button variant="outline" isLoading={archiveToggleMutation.isLoading} onClick={() => archiveToggleMutation.mutate({ tenantId, id: supplierId })}>
							{!archiveToggleMutation.isLoading && <ArchiveRestoreIcon className="size-4" />}
							{t('unarchive_supplier')}
						</Button>
					) : (
						<Button variant="outline" onClick={() => setArchiveDialogOpen(true)}>
							<ArchiveXIcon className="size-4" />
							{t('archive_supplier')}
						</Button>
					)}

					<Button variant="outlineDestructive" onClick={() => setDeleteDialogOpen(true)}>
						<Trash2Icon className="size-4" />
						{t('delete_supplier')}
					</Button>
				</PageActions>
			</PageToolbar>

			<PageContent scroll>
				<Form {...form}>
					<form onSubmit={form.handleSubmit((values) => mutate(values))} className="[&_*]:disabled flex flex-col gap-y-8">
						<FormField
							control={form.control}
							name="name"
							render={({ field }) => (
								<FormItem>
									<FormLabel required>{t('name')}</FormLabel>
									<FormControl>
										<AutoComplete
											disabled={readOnly}
											isLoading={searchNameQuery.isInitialLoading}
											isError={searchNameQuery.isError}
											isFetching={searchNameQuery.isFetching}
											emptyMessage={t('no_results')}
											options={searchNameQuery.data?.map((company) => ({ label: company.name, value: company.organizationNumber })) ?? []}
											value={{
												label: field.value,
												value: field.value,
											}}
											onSearch={(val) => {
												setSearchNameValue(val);
												field.onChange(val);
												organizationNumberControl.field.onChange('');
											}}
											searchValue={searchNameValue}
											onValueChange={(opt) => {
												field.onChange(opt.label || '');
												organizationNumberControl.field.onChange(opt.value);
											}}
										/>
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<div className="flex gap-x-4">
							<FormField
								control={form.control}
								name="organizationNumber"
								render={({ field }) => (
									<FormItem className="w-full">
										<FormLabel required>{t('organization_number')}</FormLabel>
										<FormControl>
											<Input placeholder="989 989 989" {...field} disabled={readOnly} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="internalId"
								render={({ field }) => (
									<FormItem className="w-full">
										<FormLabel required={false}>{t('internal_id')}</FormLabel>
										<FormControl>
											<Input placeholder="12345" {...field} disabled={readOnly} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>

						<FormField
							control={form.control}
							name="status"
							render={({ field }) => (
								<FormItem>
									<FormLabel required>{t('risk_evaluation')}</FormLabel>
									<SelectRiskEvaluation onChange={field.onChange} value={field.value} disabled={readOnly} />
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="mandatoryDocumentCategories"
							render={({ field }) => (
								<FormItem className="w-full">
									<FormLabel required={false}>{t('mandatory_documents')}</FormLabel>
									<SelectMultipleDocumentCategories
										{...field}
										values={field.value.map((id) => ({ id }))}
										onChange={(val) => field.onChange(val.map((v) => v.id))}
										disabled={readOnly}
									/>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="groups"
							render={({ field }) => (
								<FormItem className="w-full">
									<FormLabel required={false}>{t('groups')}</FormLabel>
									<SelectMultipleGroups
										{...field}
										values={field.value.map((id) => ({ id }))}
										onChange={(val) => field.onChange(val.map((v) => v.id))}
										disabled={readOnly}
									/>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="projects"
							render={({ field }) => (
								<FormItem className="w-full">
									<FormLabel required={false}>{t('projects')}</FormLabel>
									<SelectMultipleProjects
										{...field}
										values={field.value.map((id) => ({ id }))}
										onChange={(val) => field.onChange(val.map((v) => v.id))}
										disabled={readOnly}
									/>

									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="departments"
							render={({ field }) => (
								<FormItem className="w-full">
									<FormLabel required={false}>{t('departments')}</FormLabel>
									<SelectMultipleDepartments
										{...field}
										values={field.value.map((id) => ({ id }))}
										onChange={(val) => field.onChange(val.map((v) => v.id))}
										disabled={readOnly}
									/>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="automaticCases"
							render={({ field }) => (
								<FormItem className="w-full">
									<FormLabel required={false}>{t('add_to_automatic case')}</FormLabel>
									<SelectMultipleAutomaticCases
										{...field}
										values={field.value.map((id) => ({ id }))}
										onChange={(val) => field.onChange(val.map((v) => v.id))}
										disabled={readOnly}
									/>
									<FormDescription>{t('add_to_automatic_case_description')}</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<div className="flex justify-end gap-4">
							<LinkButton variant="outline" to="../">
								{t('cancel')}
							</LinkButton>
							<Button isLoading={isLoading} type="submit" disabled={readOnly}>
								{t('save_changes')}
							</Button>
						</div>
					</form>
				</Form>
			</PageContent>
			<AlertDialog open={archiveDialogOpen} onOpenChange={setArchiveDialogOpen}>
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle>{t('confirm_archive_title')}</AlertDialogTitle>
						<AlertDialogDescription>{t('confirm_archive_supplier_description', { name: supplier.data?.name })}</AlertDialogDescription>
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel disabled={isLoading}>{t('cancel')}</AlertDialogCancel>
						<AlertDialogAction asChild>
							<Button
								variant="destructive"
								isLoading={isLoading}
								onClick={() =>
									archiveToggleMutation.mutate({
										id: supplierId,
										tenantId,
									})
								}
							>
								{t('archive_supplier')}
							</Button>
						</AlertDialogAction>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
			<AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle>{t('confirm_delete_title')}</AlertDialogTitle>
						<AlertDialogDescription>{t('confirm_delete_supplier_description', { name: supplier.data?.name })}</AlertDialogDescription>
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel disabled={isLoading}>{t('cancel')}</AlertDialogCancel>
						<AlertDialogAction asChild>
							<Button
								variant="destructive"
								isLoading={isLoading}
								onClick={() =>
									deleteMutation.mutate({
										id: supplierId,
										tenantId,
									})
								}
							>
								{t('delete')}
							</Button>
						</AlertDialogAction>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</Page>
	);
}
