import { LinkButton } from '@/components/buttons/link-button';
import { ChartContainer } from '@/components/charts/chart-layout';
import { ChartZone } from '@/components/charts/chart-zone';
import { Page, PageActions, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';
import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { PopoverClose } from '@radix-ui/react-popover';
import { AlertCircleIcon, Building2Icon, ChevronDownIcon, FileInputIcon, UserIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const smallChartOptionsLeft = ['text_chart_cases_ready_for_processing', 'text_chart_cases_no_response', 'text_chart_inspections_ready_for_processing'] as const;
const smallChartOptionsRight = ['text_chart_cases_opened_within_deadline', 'text_chart_cases_answered_within_deadline'] as const;

const mediumChartOptionsLeft = ['pie_chart_suppliers_status_overview'] as const;
const mediumChartOptionsRight = ['bar_chart_cases_12_months', 'bar_chart_inspections_12_months'] as const;

const largeChartOptions = ['calendar_chart_cases', 'calendar_chart_inspections'] as const;

export function FrontPage() {
	const { t } = useTranslation();

	return (
		<Page>
			<PageToolbar>
				<PageTitle>{t('overview')}</PageTitle>

				<PageActions>
					<Popover>
						<PopoverTrigger asChild>
							<Button>
								{t('actions')}
								<ChevronDownIcon className="size-4" />
							</Button>
						</PopoverTrigger>
						<PopoverContent className="flex w-auto flex-col justify-start gap-y-2 p-1">
							<PopoverClose asChild>
								<LinkButton to={'./cases/create'} variant="ghost" className="justify-start">
									<FileInputIcon className="size-4" />
									{t('create_case')}
								</LinkButton>
							</PopoverClose>
							<PopoverClose asChild>
								<LinkButton to={'./deviations/create'} variant="ghost" className="justify-start">
									<AlertCircleIcon className="size-4" />
									{t('new_deviation')}
								</LinkButton>
							</PopoverClose>
							<PopoverClose asChild>
								<LinkButton to={'./suppliers/create'} variant="ghost" className="justify-start">
									<Building2Icon className="size-4" />
									{t('supplier_create')}
								</LinkButton>
							</PopoverClose>
							<PopoverClose asChild>
								<LinkButton to={'./settings/users/create'} variant="ghost" className="justify-start">
									<UserIcon className="size-4" />
									{t('create_user')}
								</LinkButton>
							</PopoverClose>
						</PopoverContent>
					</Popover>
				</PageActions>
			</PageToolbar>

			<PageContent scroll className="gap-4">
				<ChartContainer className="md:grid-cols-2 lg:grid-cols-4">
					<ChartZone size="sm" zoneId="frontpage_small_zone_1" defaultChart="text_chart_cases_ready_for_processing" chartOptions={smallChartOptionsLeft} />
					<ChartZone
						size="sm"
						zoneId="frontpage_small_zone_2"
						defaultChart="text_chart_inspections_ready_for_processing"
						chartOptions={smallChartOptionsLeft}
					/>
					<ChartZone size="sm" zoneId="frontpage_small_zone_3" defaultChart="text_chart_cases_opened_within_deadline" chartOptions={smallChartOptionsRight} />
					<ChartZone size="sm" zoneId="frontpage_small_zone_4" defaultChart="text_chart_cases_answered_within_deadline" chartOptions={smallChartOptionsRight} />
				</ChartContainer>
				<ChartContainer className="lg:grid-cols-2">
					<ChartZone size="md" zoneId="frontpage_medium_zone_1" defaultChart="pie_chart_suppliers_status_overview" chartOptions={mediumChartOptionsLeft} />
					<ChartZone size="md" zoneId="frontpage_medium_zone_2" defaultChart="bar_chart_inspections_12_months" chartOptions={mediumChartOptionsRight} />
				</ChartContainer>
				<ChartContainer>
					<ChartZone size="lg" zoneId="frontpage_large_zone_1" defaultChart="calendar_chart_inspections" chartOptions={largeChartOptions} />
				</ChartContainer>
			</PageContent>
		</Page>
	);
}
