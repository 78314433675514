import { ChartContentLayout } from './chart-layout';
import { ResponsiveCalendar } from '@nivo/calendar';
import { useDateFns } from '@/hooks/useDateFns';
import { trpc } from '@/lib/providers/trpc';
import { addDays, endOfYear, startOfYear } from 'date-fns';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { useSupplierId } from '@/hooks/useSupplier';
import { parseServerTableFiltersIntoURLParams } from '../tables/table-utils';
import { useNavigate } from 'react-router-dom';

export function CalendarChartInspections() {
	const { format } = useDateFns();
	const tenantId = useTenantIdSafe();
	const supplierId = useSupplierId();
	const navigate = useNavigate();

	const fromDate = startOfYear(new Date());
	const toDate = endOfYear(new Date());

	const {
		data = [],
		isLoading,
		error,
	} = trpc.tenantWidgetInspectionsCalendar.useQuery(
		{
			fromDate: fromDate.toISOString(),
			toDate: toDate.toISOString(),
			supplierId,
			tenantId,
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		},
		{
			select(data) {
				return data.map(({ date, numberOfInspections }) => ({ day: date, value: numberOfInspections }));
			},
		},
	);

	const supplierQuery = trpc.tenantSuppliersById.useQuery(
		{ id: supplierId!, tenantId },
		{
			enabled: !!supplierId,
		},
	);

	return (
		<ChartContentLayout isLoading={isLoading} error={error}>
			<ResponsiveCalendar
				data={data}
				direction="horizontal"
				tooltip={({ day, value }) => (
					<div className="z-50 overflow-hidden rounded-md bg-primary px-1.5 py-1.5 text-xs text-primary-foreground animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2">
						<div className="flex items-center gap-1">
							<time>{format(new Date(day), 'PP')}:</time>
							<strong>{value}</strong>
						</div>
					</div>
				)}
				onClick={(event) => {
					const supplierFilter = supplierQuery.data?.name ? { id: 'supplierName', value: [supplierQuery.data.name] } : undefined;

					const params = parseServerTableFiltersIntoURLParams({
						filters: [supplierFilter],
						timeFilters: [
							{
								id: 'createdAt',
								fromTime: event.date.toISOString(),
								toTime: addDays(event.date, 1).toISOString(),
							},
						],
						pageIndex: 0,
						search: '',
						sorting: [
							{
								desc: true,
								id: 'createdAt',
							},
						],
						filterOrder: supplierFilter ? ['supplierName', 'createdAt'] : ['createdAt'],
					});
					navigate(`/tenants/${tenantId}/inspections?${params.toString()}`);
				}}
				from={fromDate}
				to={toDate}
				emptyColor="hsl(var(--muted))"
				colors={[`hsla(var(--timp), 0.25)`, `hsla(var(--timp), 0.5)`, `hsla(var(--timp), 0.75)`, `hsla(var(--timp), 1)`]}
				margin={{ top: 20, right: 20 }}
				daySpacing={2}
				yearSpacing={40}
				dayBorderWidth={2}
				dayBorderColor="hsl(var(--background))"
				monthBorderColor="transparent"
				yearLegendPosition="after"
				legends={[
					{
						anchor: 'bottom-right',
						direction: 'row',
						translateY: 36,
						itemCount: 4,
						itemWidth: 42,
						itemHeight: 36,
						itemsSpacing: 14,
						itemDirection: 'right-to-left',
					},
				]}
			/>
		</ChartContentLayout>
	);
}
