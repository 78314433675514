import { Page, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';
import { ServerDataTable } from '@/components/tables/server-data-table';
import { DataTableLink } from '@/components/ui/data-table-link';
import { RelativeDate } from '@/components/ui/relative-date';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { trpc } from '@/lib/providers/trpc';
import { useTranslation } from 'react-i18next';

export function CaseEmailsFailedTasksPage() {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();

	return (
		<Page>
			<PageToolbar>
				<PageTitle>{t('case_failed_emails_task')}</PageTitle>
			</PageToolbar>
			<PageContent>
				<ServerDataTable
					columnsStorageKey="case-emails-failed-tasks-page"
					query={trpc.tenantTasksCasesEmailsFailedList}
					params={{
						tenantId,
					}}
					columns={[
						{
							id: 'caseId',
							title: t(`description`),
							render: (row) => {
								return <DataTableLink to={`/tenants/${tenantId}/cases/${row.caseId}`}>									
									{t('case_failed_emails_task_description', {email: row.email})}
								</DataTableLink>;
							},
						},
						{
							id: 'supplierName',
							title: t(`supplier`),
							filter: true,
							enableSorting: true,
						},
						{
							id: 'formTemplateName',
							title: t(`form_template`),
							filter: true,
							enableSorting: false,
						},
						{
							id: 'lastSendAttemptAt',
							title: t(`last_send_attempt_at`),
							render: (row) => <RelativeDate date={row.lastSendAttemptAt} />,
						},
					]}
					defaultSortColumn="lastSendAttemptAt"
					defaultSortOrder="desc"
					paginationPageSize={50}
				/>
			</PageContent>
		</Page>
	);
}
