import { cn } from '@/lib/utils';
import { Link } from '@/components/links/Link';

interface Props {
	to: string;
	children: React.ReactNode;
	className?: string;
}
export function DataTableLink({ to, children, className, ...props }: Props) {
	return (
		<Link {...props} to={to} className={cn('hover:underline', className)}>
			{children}
		</Link>
	);
}
