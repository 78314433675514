import { Page, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';
import { ServerDataTable } from '@/components/tables/server-data-table';
import { DataTableLink } from '@/components/ui/data-table-link';
import { RelativeDate } from '@/components/ui/relative-date';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { trpc } from '@/lib/providers/trpc';
import { cn } from '@/lib/utils';
import { useTranslation } from 'react-i18next';

export function DocumentsExpiredTasksPage() {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();
	return (
		<Page>
			<PageToolbar>
				<PageTitle>{t('expired_documents')}</PageTitle>
			</PageToolbar>
			<PageContent>
				<ServerDataTable
					columnsStorageKey="documents-expired-tasks-page"
					query={trpc.tenantTasksDocumentsExpiredList}
					params={{
						tenantId,
					}}
					columns={[
						{
							id: 'documentName',
							title: t(`document_name`),
							enableSorting: true,
							render: (row) => {
								const description = t('documents_expired_task_description', { supplierName: row.supplierName, documentCategoryName: row.documentCategoryName });

								return (
									<DataTableLink
										to={`/tenants/${tenantId}/suppliers/${row.supplierId}/documents/create?documentCategoryId=${row.documentCategoryId}&name=${row.documentName}&locked=true`}
									>
										{description}
									</DataTableLink>
								);
							},
						},
						{
							id: 'documentCategoryName',
							title: t(`category`),
							filter: true,
						},
						{
							id: 'supplierName',
							title: t(`supplier`),
							filter: true,
							enableSorting: true,
							render: (row) => {
								return <DataTableLink to={`/tenants/${tenantId}/suppliers/${row.supplierId}`}>{row.supplierName}</DataTableLink>;
							},
						},
						{
							id: 'expiresAt',
							title: t(`expires_at`),
							enableSorting: true,
							render: (row) => {
								const hasExpired = new Date(row.expiresAt) < new Date();
								return <RelativeDate date={row.expiresAt} className={cn(hasExpired && 'text-destructive')} />;
							},
						},
					]}
					defaultSortColumn="documentCategoryName"
					defaultSortOrder="asc"
					paginationPageSize={50}
				/>
			</PageContent>
		</Page>
	);
}
