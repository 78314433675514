import { DataTable } from '@/components/tables/data-table';
import { trpc } from '@/lib/providers/trpc';
import { useTranslation } from 'react-i18next';
import { LinkButton } from '@/components/buttons/link-button';
import { MutationActionsMenu, MutationDeleteAction, MutationEditAction } from '@/components/dropdown-menus/mutation-actions-menu';
import { DataTableLink } from '@/components/ui/data-table-link';
import { useTenantId } from '@/hooks/useTenant';
import { Page, PageActions, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';

export function SysAdminVariablesPage() {
	const { t } = useTranslation();
	const tenantId = useTenantId();

	const { data = [], isLoading, error } = trpc.systemVariablesList.useQuery({ tenantId });
	const { mutate: deleteMutation } = trpc.systemVariablesDeleteById.useMutation();

	return (
		<Page isLoading={isLoading} error={error}>
			<PageToolbar>
				<PageTitle>{t('variables_title')}</PageTitle>

				<PageActions>
					<LinkButton to="./create">{t('variable_create')}</LinkButton>
				</PageActions>
			</PageToolbar>

			<PageContent>
				<DataTable
					columnsStorageKey="sys-admin-variables-page"
					isLoading={isLoading}
					error={error}
					data={data}
					columns={[
						{
							id: 'id',
							title: t(`variable_name`),
							render(row) {
								if (row.isPredefined) return <span>{row.id}</span>;
								return <DataTableLink to={`./${row.id}/edit`}>{row.id}</DataTableLink>;
							},
						},
						{
							id: 'value',
							title: t(`value`),
						},
						{
							id: 'id',
							title: t('actions'),
							actions: true,
							enableSorting: false,
							size: 40,
							render(row) {
								if (row.isPredefined) return null;
								return (
									<MutationActionsMenu data-testid={row.id}>
										<MutationEditAction to={`./${row.id}/edit`} />
										<MutationDeleteAction name={row.id} onConfirmDelete={() => deleteMutation({ id: row.id })} />
									</MutationActionsMenu>
								);
							},
						},
					]}
					searchColumn="id"
					defaultSortColumn="id"
				/>
			</PageContent>
		</Page>
	);
}
