import { LinkButton } from '@/components/buttons/link-button';
import { DeleteDialog } from '@/components/dropdown-menus/mutation-actions-menu';
import { DeviationSeverityIconLabel } from '@/components/labels/deviation-severity-icon-label';
import { Page, PageActions, PageTitle, PageToolbar, PageContent } from '@/components/layouts/page';
import { Button } from '@/components/ui/button';
import { RelativeDate } from '@/components/ui/relative-date';
import { TypographyLabel, TypographyMuted } from '@/components/ui/typography';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { trpc } from '@/lib/providers/trpc';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export function DeviationsDetailsPage() {
	const { t } = useTranslation();
	const { id } = useParams();
	const tenantId = useTenantIdSafe();
	const [dialogOpen, setDialogOpen] = useState(false);
	const navigate = useNavigate();

	const { data, isLoading, error } = trpc.tenantDeviationsById.useQuery({
		id: id!,
		tenantId,
	});

	const deleteById = trpc.tenantDeviationsDeleteById.useMutation({
		onSuccess() {
			navigate('../', {
				unstable_flushSync: true,
			});
		},
	});

	return (
		<Page size="container" isLoading={isLoading} error={error}>
			<PageToolbar>
				<PageTitle backLink>{t('deviation_details')}</PageTitle>

				<PageActions>
					<Button
						variant="outlineDestructive"
						onClick={() => {
							setDialogOpen(true);
						}}
					>
						{t('delete_deviation')}
					</Button>
					<DeleteDialog
						onConfirmDelete={() => {
							deleteById.mutate({ tenantId, id: id! });
						}}
						open={dialogOpen}
						setOpen={setDialogOpen}
						isLoading={deleteById.isLoading}
					></DeleteDialog>
				</PageActions>
			</PageToolbar>

			{data && (
				<PageContent scroll>
					<div className="mt-8 flex flex-col gap-y-8 overflow-y-auto">
						<div className="flex gap-x-4">
							<div className="flex basis-1/2 flex-col gap-y-2 rounded-md bg-muted p-3">
								<TypographyLabel>{t('severity')}</TypographyLabel>
								<DeviationSeverityIconLabel severity={data.severity} />
							</div>
							<div className="flex basis-1/2 flex-col gap-y-2 rounded-md bg-muted p-3">
								<TypographyLabel>{t('type')}</TypographyLabel>
								<div>
									{data.type === 'CASE' && (
										<LinkButton variant="link" className="inline p-0 text-blue-600" to={`/tenants/${tenantId}/cases/${data.caseId}`}>
											{t(data.type)}
										</LinkButton>
									)}
									{data.type === 'INSPECTION' && (
										<LinkButton variant="link" className="inline p-0 text-blue-600" to={`/tenants/${tenantId}/inspections/${data.inspectionId}`}>
											{t(data.type)}
										</LinkButton>
									)}
									{data.type === 'MANUAL' && (
										<LinkButton variant="link" className="inline p-0 text-blue-600" to={`/tenants/${tenantId}/suppliers/${data.supplierId}`}>
											{t('supplier')}
										</LinkButton>
									)}
								</div>
							</div>
						</div>
						<div className="flex gap-x-4">
							<div className="flex basis-1/2 flex-col gap-y-2 rounded-md bg-muted p-3">
								<TypographyLabel>{t('created_by')}</TypographyLabel>
								<TypographyMuted>{data.createdByName}</TypographyMuted>
							</div>
							<div className="flex basis-1/2 flex-col gap-y-2 rounded-md bg-muted p-3">
								<TypographyLabel>{t('created_at')}</TypographyLabel>
								<TypographyMuted>
									<RelativeDate date={data.createdAt} />
								</TypographyMuted>
							</div>
						</div>
						<div className="flex">
							<div className="flex min-h-24 basis-full flex-col gap-y-2 rounded-md bg-muted p-3">
								<TypographyLabel>{t('description')}</TypographyLabel>
								<TypographyMuted>{data.description}</TypographyMuted>
							</div>
						</div>
					</div>
				</PageContent>
			)}
		</Page>
	);
}
