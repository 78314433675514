import * as z from 'zod';

const CONSTANT_CASE_REGEX = /^[A-Z_]+$/;

export const systemVariablesInsertInputSchema = z.object({
	id: z.string().min(1).max(250).regex(CONSTANT_CASE_REGEX),
	value: z.string().min(1).max(1000),
});

export const systemVariablesInsertOutputSchema = z.object({
	id: z.string(),
});
