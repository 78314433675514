import { Page, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';
import { ServerDataTable } from '@/components/tables/server-data-table';
import { Badge } from '@/components/ui/badge';
import { DataTableLink } from '@/components/ui/data-table-link';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { trpc } from '@/lib/providers/trpc';
import { useTranslation } from 'react-i18next';

export function InspectionMissingTasksPage() {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();
	return (
		<Page>
			<PageToolbar>
				<PageTitle>{t('inspection_missing_tasks')}</PageTitle>
			</PageToolbar>
			<PageContent>
				<ServerDataTable
					columnsStorageKey="inspection-missing-tasks-page"
					query={trpc.tenantTasksInspectionsMissingList}
					params={{ tenantId }}
					columns={[
						{
							id: 'formTemplateId',
							title: t(`description`),
							render: (row) => {
								const description = t('inspection_missing_description', {
									formTemplateName: row.formTemplateName,
									currentCount: row.currentCount,
									minimum14DayRollingUsage: row.minimum14DayRollingUsage,
								});
								return (
									<a className="hover:underline" href={`/inspector/tenants/${tenantId}/forms/${row.formTemplateId}`}>
										{description}
									</a>
								);
							},
						},
						{
							id: 'formTemplateName',
							title: t(`form_template`),
							filter: true,
							enableSorting: false,
							render: (row) => {
								return <DataTableLink to={`/tenants/${tenantId}/settings/inspection-forms/${row.formTemplateId}/edit`}>{row.formTemplateName}</DataTableLink>;
							},
						},
						{
							id: 'missingCount',
							title: t('missing_inspections_count'),
							enableSorting: true,
							render: (row) => <Badge variant="secondary">{row.missingCount}</Badge>,
						},
					]}
					defaultSortColumn="missingCount"
					defaultSortOrder="desc"
					paginationPageSize={50}
				/>
			</PageContent>
		</Page>
	);
}
