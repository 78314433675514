import { Page, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';
import { ServerDataTable } from '@/components/tables/server-data-table';
import { DataTableLink } from '@/components/ui/data-table-link';
import { RelativeDate } from '@/components/ui/relative-date';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { trpc } from '@/lib/providers/trpc';
import { useTranslation } from 'react-i18next';

export function CaseNoResponseTasksPage() {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();

	return (
		<Page>
			<PageToolbar>
				<PageTitle>{t('case_noresponse_tasks')}</PageTitle>
			</PageToolbar>
			<PageContent>
				<ServerDataTable
					columnsStorageKey="case-noresponse-tasks-page"
					query={trpc.tenantTasksCasesNoResponseList}
					params={{ tenantId }}
					columns={[
						{
							id: 'caseId',
							title: t(`description`),
							render: (row) => {
								const description = t('case_noresponse_task_desciption', { supplierName: row.supplierName, formTemplateName: row.formTemplateName });
								return <DataTableLink to={`/tenants/${tenantId}/cases/${row.caseId}`}>{description}</DataTableLink>;
							},
						},
						{
							id: 'supplierName',
							title: t(`supplier`),
							filter: true,
							enableSorting: true,
						},
						{
							id: 'formTemplateName',
							title: t(`form_template`),
							filter: true,
							enableSorting: false,
						},
						{
							id: 'responseDeadline',
							title: t(`response_deadline`),
							render: (row) => <RelativeDate date={row.responseDeadline} className="text-destructive" />,
						},
					]}
					defaultSortColumn="responseDeadline"
					defaultSortOrder="asc"
					paginationPageSize={50}
				/>
			</PageContent>
		</Page>
	);
}
