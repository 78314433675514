import * as z from 'zod';

export const tenantProjectsInsertInputSchema = z.object({
	name: z.string().min(1).max(250),
	supplierIds: z.array(z.string().uuid()).optional(),
});

export const tenantProjectsInsertOutputSchema = z.object({
	id: z.string().uuid(),
});
