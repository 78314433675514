import { ChartContentLayout } from './chart-layout';
import { ResponsivePie, ComputedDatum } from '@nivo/pie';
import colors from 'tailwindcss/colors';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { parseServerTableFiltersIntoURLParams } from '../tables/table-utils';
import { trpc } from '@/lib/providers/trpc';
import { TypographySmall } from '../ui/typography';

export function PieChartSuppliersStatusOverview() {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();
	const navigate = useNavigate();

	const query = trpc.tenantWidgetSuppliersStatusOverview.useQuery(
		{ tenantId },
		{
			initialData: [],
			select(data) {
				return data.map((item) => ({
					id: item.status,
					label: t(item.status),
					value: item.count,
				}));
			},
		},
	);

	return (
		<ChartContentLayout isLoading={query.isLoading} error={query.error}>
			<div className="h-full w-full">
				{query.data.length === 0 && (
					<div className="pointer-events-none absolute inset-0 z-10 flex items-center justify-center">
						<TypographySmall className="text-muted-foreground">{t('no_results')}</TypographySmall>
					</div>
				)}
				<ResponsivePie
					data={query.data}
					colors={['hsl(var(--primary))', colors.gray[400], 'hsl(var(--timp))']}
					margin={{ bottom: 24, top: 5 }} // Prevents the chart from being cut off
					innerRadius={0.7} // Hole size in the middle
					padAngle={1} // Distance between slices
					cornerRadius={8} // Corner radius of slices
					activeOuterRadiusOffset={5} // Distance of the slice when hovered
					arcLabelsTextColor={colors.white} // Color of the text in the slices
					tooltip={(e: { datum: ComputedDatum<typeof ResponsivePie> }) => {
						return (
							<div className="z-50 overflow-hidden rounded-md bg-primary px-1.5 py-1.5 text-xs text-primary-foreground animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2">
								<div className="flex items-center gap-1">
									<div className="size-4" style={{ backgroundColor: e.datum.color }}></div>
									<span>{e.datum.label}:</span>
									<strong>{e.datum.formattedValue}</strong>
								</div>
							</div>
						);
					}}
					enableArcLinkLabels={false}
					onClick={(e) => {
						const params = parseServerTableFiltersIntoURLParams({
							sorting: [],
							filters: [
								{
									id: 'status',
									value: [e.id],
								},
							],
							timeFilters: [],
							pageIndex: 0,
							search: '',
							filterOrder: ['status'],
						});
						navigate(`/tenants/${tenantId}/suppliers?${params.toString()}`);
					}}
					legends={[
						{
							anchor: 'bottom',
							direction: 'row',
							justify: false,
							translateX: 0,
							translateY: 20,
							itemsSpacing: 8,
							itemWidth: 100,
							itemHeight: 10,
							itemTextColor: 'hsl(var(--primary))',
							itemDirection: 'left-to-right',
							itemOpacity: 1,
							symbolSize: 16,
							symbolShape: 'circle',
							effects: [
								{
									on: 'hover',
									style: {
										itemOpacity: 0.8,
									},
								},
							],
						},
					]}
				/>
			</div>
		</ChartContentLayout>
	);
}
