import { useSupplierId } from '@/hooks/useSupplier';
import { TypographyBlank, TypographyH3 } from '../ui/typography';
import { ChartContentLayout } from './chart-layout';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { trpc } from '@/lib/providers/trpc';
import { keepMaxDecimals } from '@/lib/utils';

export function TextChartCasesOpenedWithinDeadline() {
	const tenantId = useTenantIdSafe();
	const supplierId = useSupplierId();

	const query = trpc.tenantWidgetCasesOpenedWithinDeadline.useQuery({
		tenantId,
		supplierId,
	});

	const percentage = query?.data?.totalSent ? (query?.data?.totalOpenedWithinDeadline / query?.data?.totalSent) * 100 || 0 : 0;

	return (
		<ChartContentLayout error={query.error} isLoading={query.isLoading}>
			<TypographyH3>{query?.data?.totalSent === 0 ? '-' : `${keepMaxDecimals(percentage, 2)}%`}</TypographyH3>
			<TypographyBlank />
		</ChartContentLayout>
	);
}
