import { DataTable } from '@/components/tables/data-table';
import { MutationActionsMenu, MutationDeleteAction, MutationEditAction } from '@/components/dropdown-menus/mutation-actions-menu';
import { RelativeDate } from '@/components/ui/relative-date';
import { trpc } from '@/lib/providers/trpc';
import { useTranslation } from 'react-i18next';
import { DataTableLink } from '@/components/ui/data-table-link';
import { Page, PageActions, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';
import { LinkButton } from '@/components/buttons/link-button';
import { cn } from '@/lib/utils';

export function SysAdminNotificationsPage() {
	const { t } = useTranslation();
	const { data = [], isLoading, error } = trpc.sysadminNotificationsList.useQuery();

	const { mutate: deleteNotification } = trpc.sysadminNotificationsDeleteById.useMutation();

	return (
		<Page isLoading={isLoading} error={error}>
			<PageToolbar>
				<PageTitle>{t('notifications_title')}</PageTitle>
				<PageActions>
					<LinkButton to="./create">{t('create_notification')}</LinkButton>
				</PageActions>
			</PageToolbar>

			<PageContent>
				<DataTable
					columnsStorageKey="sys-admin-notifications-page"
					isLoading={isLoading}
					error={error}
					data={data}
					columns={[
						{
							id: 'title',
							title: t('title'),
							render: (row) => {
								return <DataTableLink to={`./${row.id}/edit`}>{row.title}</DataTableLink>;
							},
						},
						{
							id: 'summary',
							title: t(`summary`),
						},
						{
							id: 'type',
							title: t('type'),
							filter: true,
							render: (row) => t(row.type),
							valueToLabel: (value) => t(value),
						},
						{
							id: 'createdAt',
							title: t(`created_at`),
							render: (row) => {
								return <RelativeDate date={row.createdAt} />;
							},
						},
						{
							id: 'expiresAt',
							title: t('expires_at'),
							enableSorting: true,
							render: (row) => {
								if (!row.expiresAt) return null;
								return <RelativeDate className={cn(new Date(row.expiresAt) < new Date() && 'text-destructive')} date={row.expiresAt} />;
							},
						},
						{
							id: 'id',
							title: t('actions'),
							actions: true,
							enableSorting: false,
							size: 40,
							render: (row) => {
								return (
									<MutationActionsMenu data-testid={row.title}>
										<MutationEditAction to={`./${row.id}/edit`} />
										<MutationDeleteAction name={row.title} onConfirmDelete={() => deleteNotification(row)} />
									</MutationActionsMenu>
								);
							},
						},
					]}
					searchColumn="title"
					defaultSortColumn="title"
				/>
			</PageContent>
		</Page>
	);
}
