import { Button } from '@/components/ui/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useController, useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { suppliersContactSchema, tenantSuppliersInsertInputSchema } from '@timp/server/src/schemas/tenant-suppliers-insert.schema';
import { useTranslation } from 'react-i18next';
import { trpc } from '@/lib/providers/trpc';
import { useNavigate } from 'react-router-dom';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { LinkButton } from '@/components/buttons/link-button';
import { Page, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { MutationActionsMenu, MutationDeleteAction, MutationEditAction } from '@/components/dropdown-menus/mutation-actions-menu';
import { useState } from 'react';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { v4 as uuid } from 'uuid';
import { TypographySmall } from '@/components/ui/typography';
import {
	SelectMultipleProjects,
	SelectMultipleDepartments,
	SelectMultipleGroups,
	SelectMultipleDocumentCategories,
	SelectMultipleAutomaticCases,
} from '@/components/selects/select-multiple';
import { Checkbox } from '@/components/ui/checkbox';
import { useBrregService } from '@/hooks/useBrregService';
import { AutoComplete } from '@/components/ui/autocomplete';
import { RichTextEditor } from '@/components/rich-text-editor/rich-text-editor';
import { SelectRiskEvaluation } from '@/components/selects/select-risk-evaluation';
import { Spinner } from '@/components/icons/spinner';

function getDefaultContact() {
	return {
		id: uuid(),
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		role: '',
	};
}

function getDefaultEmailContentTemplate(language: string) {
	if (language === 'no') {
		return [
			{
				type: 'paragraph',
				children: [
					{ text: 'Du har blitt lagt til i TIMP av ' },
					{ type: 'mention', character: 'COMPANY_NAME', children: [{ text: '' }] },
					{ text: '. Fordi du er registrert som en underleverandør.' },
				],
			},
			{ type: 'paragraph', children: [{ text: '' }] },
			{
				type: 'paragraph',
				children: [
					{ text: '' },
					{ type: 'mention', character: 'COMPANY_NAME', children: [{ text: '' }] },
					{
						text: ' bruker TIMP for å følge opp sine underleverandører. Du kan forvente å få tilsendt skjemaer hvor du blir bedt om å dokumentere at du følger de gjeldende lover og regler. ',
					},
					{ type: 'mention', character: 'COMPANY_NAME', children: [{ text: '' }] },
					{ text: ' vil utføre stikkprøvekontroll innenfor arbeidstid, lønn og andre risikoområder.' },
				],
			},
		];
	}

	return [
		{
			type: 'paragraph',
			children: [
				{
					text: '',
				},
				{
					type: 'mention',
					character: 'COMPANY_NAME',
					children: [
						{
							text: '',
						},
					],
				},
				{
					text: ' added your company (',
				},
				{
					type: 'mention',
					character: 'SUPPLIER_NAME',
					children: [
						{
							text: '',
						},
					],
				},
				{
					text: ') into the TIMP platform, you should expect some form emails in the future, which should be filled out.',
				},
			],
		},
	];
}

export function SuppliersCreatePage() {
	const tenantId = useTenantIdSafe();
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [searchNameValue, setSearchNameValue] = useState<string>('');
	const searchNameQuery = useBrregService(searchNameValue, { keepPreviousData: true });
	const [contactDialogOpen, setContactDialogOpen] = useState(false);
	const [editIndex, setEditIndex] = useState<number | null>(null);

	const { mutate, isLoading } = trpc.tenantSuppliersInsert.useMutation({
		onSuccess(data) {
			navigate(`../${data.id}`);
		},
	});

	const form = useForm<z.infer<typeof tenantSuppliersInsertInputSchema>>({
		resolver: zodResolver(tenantSuppliersInsertInputSchema),
		defaultValues: {
			tenantId,
			groups: [],
			departments: [],
			projects: [],
			mandatoryDocumentCategories: [],
			automaticCases: [],
			contacts: [],
			status: 'OK',
			sendWelcomeEmail: false,
			sendWelcomeEmailContent: getDefaultEmailContentTemplate(i18n.language), // Retrieves the default message for the welcome email
		},
	});

	const {
		append: appendContact,
		remove: removeContact,
		update: updateContact,
		fields: contactsFields,
	} = useFieldArray({
		control: form.control,
		name: 'contacts',
	});

	const contactForm = useForm<z.infer<typeof suppliersContactSchema>>({
		resolver: zodResolver(suppliersContactSchema),
		defaultValues: getDefaultContact(),
	});
	const isNewContact = editIndex === null;
	const emailTemplateContent = useController({
		name: 'sendWelcomeEmailContent',
		control: form.control,
	});

	const organizationNumberControl = useController({
		name: 'organizationNumber',
		control: form.control,
	});

	const emailValidation = trpc.validateEmail.useMutation({
		onSuccess(validationResult) {
			if (validationResult.suggestedEmail) {
				contactForm.setError('email', {
					type: 'manual',
					message: t('email_invalid_please_check_suggestion', { email: validationResult.suggestedEmail }),
				});
			} else if (!validationResult.isValid) {
				contactForm.setError('email', {
					type: 'manual',
					message: t('email_invalid_please_check'),
				});
			} else {
				contactForm.clearErrors('email');
			}
		},
	});
	const [isSubmitting, setIsSubmitting] = useState(false);

	return (
		<Page size="container">
			<PageToolbar>
				<PageTitle backLink>{t('create')}</PageTitle>
			</PageToolbar>
			<PageContent scroll>
				<Form {...form}>
					<form onSubmit={form.handleSubmit((data) => mutate(data))} className="flex flex-col gap-y-8">
						<FormField
							control={form.control}
							name="name"
							render={({ field }) => (
								<FormItem>
									<FormLabel required>{t('name')}</FormLabel>
									<FormControl>
										<AutoComplete
											isLoading={searchNameQuery.isInitialLoading}
											isError={searchNameQuery.isError}
											isFetching={searchNameQuery.isFetching}
											emptyMessage={t('no_results')}
											options={searchNameQuery.data?.map((company) => ({ label: company.name, value: company.organizationNumber })) ?? []}
											value={{
												label: field.value,
												value: field.value,
											}}
											onSearch={(val) => {
												setSearchNameValue(val);
												field.onChange(val);
												organizationNumberControl.field.onChange('');
											}}
											searchValue={searchNameValue}
											onValueChange={(opt) => {
												field.onChange(opt.label || '');
												organizationNumberControl.field.onChange(opt.value);
											}}
										/>
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>

						<div className="flex gap-x-4">
							<FormField
								control={form.control}
								name="organizationNumber"
								render={({ field }) => (
									<FormItem className="w-full">
										<FormLabel required>{t('organization_number')}</FormLabel>
										<FormControl>
											<Input placeholder="989 989 989" {...field} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>

							<FormField
								control={form.control}
								name="internalId"
								render={({ field }) => (
									<FormItem className="w-full">
										<FormLabel required={false}>{t('internal_id')}</FormLabel>
										<FormControl>
											<Input placeholder="12345" {...field} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>

						<FormField
							control={form.control}
							name="status"
							render={({ field }) => (
								<FormItem>
									<FormLabel required>{t('risk_evaluation')}</FormLabel>
									<SelectRiskEvaluation onChange={field.onChange} value={field.value} />
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="mandatoryDocumentCategories"
							render={({ field }) => (
								<FormItem className="w-full">
									<FormLabel required={false}>{t('mandatory_documents')}</FormLabel>
									<SelectMultipleDocumentCategories values={field.value.map((id) => ({ id }))} onChange={(val) => field.onChange(val.map((v) => v.id))} />
									<FormDescription>{t('mandatory_documents_description')}</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="groups"
							render={({ field }) => (
								<FormItem className="w-full">
									<FormLabel required={false}>{t('groups')}</FormLabel>
									<SelectMultipleGroups values={field.value.map((id) => ({ id }))} onChange={(val) => field.onChange(val.map((v) => v.id))} />
									<FormDescription>{t('add_groups_description')}</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="projects"
							render={({ field }) => (
								<FormItem className="w-full">
									<FormLabel required={false}>{t('projects')}</FormLabel>
									<SelectMultipleProjects values={field.value.map((id) => ({ id }))} onChange={(val) => field.onChange(val.map((v) => v.id))} />
									<FormDescription>{t('add_projects_description')}</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="departments"
							render={({ field }) => (
								<FormItem className="w-full">
									<FormLabel required={false}>{t('departments')}</FormLabel>
									<SelectMultipleDepartments values={field.value.map((id) => ({ id }))} onChange={(val) => field.onChange(val.map((v) => v.id))} />
									<FormDescription>{t('add_departments_description')}</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="automaticCases"
							render={({ field }) => (
								<FormItem className="w-full">
									<FormLabel required={false}>{t('add_to_automatic case')}</FormLabel>
									<SelectMultipleAutomaticCases values={field.value.map((id) => ({ id }))} onChange={(val) => field.onChange(val.map((v) => v.id))} />
									<FormDescription>{t('add_to_automatic_case_description')}</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<div className="flex items-center justify-between">
							<FormLabel required={true} className="flex items-center gap-x-1">
								{t('supplier_contacts')}
							</FormLabel>
							<button
								type="button"
								onClick={() => {
									contactForm.reset(getDefaultContact());
									setContactDialogOpen(true);
								}}
							>
								<TypographySmall className="text-blue-600 hover:underline">+ {t('create_contact')}</TypographySmall>
							</button>
						</div>

						<Table>
							<TableHeader>
								<TableRow>
									<TableHead className="w-40">{t('full_name')}</TableHead>
									<TableHead>{t('email')}</TableHead>
									<TableHead>{t('phone')}</TableHead>
									<TableHead>{t('role')}</TableHead>
									<TableHead></TableHead>
								</TableRow>
							</TableHeader>
							<TableBody>
								{contactsFields.map((field, index) => (
									<TableRow key={field.id + index}>
										<TableCell>{field.fullName}</TableCell>
										<TableCell>
											<a href={`mailto:${field.email}`} className="underline">
												{field.email}
											</a>
										</TableCell>
										<TableCell>{field.phone}</TableCell>
										<TableCell>{field.role}</TableCell>
										<TableCell className="flex items-center justify-end gap-x-2">
											<MutationActionsMenu>
												<MutationEditAction
													onEdit={() => {
														setContactDialogOpen(true);
														setEditIndex(index);
														contactForm.reset(field);
													}}
												/>
												<MutationDeleteAction name={field.fullName} onConfirmDelete={() => removeContact(index)} />
											</MutationActionsMenu>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>

						<FormField
							control={form.control}
							name="contacts"
							render={() => (
								<FormItem className="w-full">
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="sendWelcomeEmail"
							render={({ field }) => (
								<FormItem className="w-full">
									<div className="flex items-center gap-x-2 space-y-0">
										<FormControl>
											<Checkbox onCheckedChange={field.onChange} name={field.name} checked={field.value} />
										</FormControl>
										<FormLabel required={false}>{t('send_welcome_email')}</FormLabel>
									</div>

									{field.value && (
										<div className="overflow-hidden">
											<RichTextEditor
												disableFormatting
												initialValue={emailTemplateContent.field.value as unknown as Node[]}
												onChange={emailTemplateContent.field.onChange}
											/>
										</div>
									)}

									<FormDescription>{t('send_welcome_email_description')}</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<div className="flex justify-end gap-4">
							<LinkButton variant="outline" to="../">
								{t('cancel')}
							</LinkButton>

							<Button isLoading={isLoading} type="submit">
								{t('supplier_create')}
							</Button>
						</div>
					</form>
				</Form>
			</PageContent>
			<form
				onSubmit={contactForm.handleSubmit((contact) => {
					setIsSubmitting(true);

					if (!contactForm.getFieldState('email').error) {
						if (isNewContact) {
							appendContact(contact);
						} else {
							updateContact(editIndex, contact);
						}
						contactForm.reset(getDefaultContact());
						setEditIndex(null);
						setContactDialogOpen(false);
						setIsSubmitting(false);
					} else {
						setIsSubmitting(false);
					}
				})}
			>
				<Dialog
					onOpenChange={() => {
						contactForm.reset(getDefaultContact());
						setEditIndex(null);
						setContactDialogOpen(false);
					}}
					open={contactDialogOpen}
				>
					<DialogContent className="sm:max-w-[425px]">
						<DialogHeader>
							<DialogTitle>{editIndex === null ? t('create_contact') : t('edit_contact')}</DialogTitle>
						</DialogHeader>
						<Form {...contactForm}>
							<FormField
								control={contactForm.control}
								name="fullName"
								render={({ field }) => (
									<FormItem>
										<FormLabel required>{t('full_name')}</FormLabel>
										<FormControl>
											<Input {...field} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={contactForm.control}
								name="email"
								render={({ field }) => (
									<FormItem>
										<FormLabel required>{t('email')}</FormLabel>

										<div className="relative">
											<FormControl>
												<Input
													{...field}
													onBlur={() => {
														contactForm.trigger('email').then((isValid) => {
															if (isValid) {
																emailValidation.mutate({ email: field.value });
															}
														});
													}}
												/>
											</FormControl>
											{emailValidation.isLoading && (
												<div className="absolute inset-y-0 right-0 flex items-center pr-3">
													<Spinner />
												</div>
											)}
										</div>
										{emailValidation.data?.suggestedEmail ? (
											<button
												type="button"
												className="inline [&>p]:hover:underline"
												onClick={() => {
													field.onChange(emailValidation.data.suggestedEmail);
													emailValidation.reset();
													contactForm.clearErrors('email');
												}}
											>
												<FormMessage />
											</button>
										) : (
											<FormMessage />
										)}
									</FormItem>
								)}
							/>
							<FormField
								control={contactForm.control}
								name="phone"
								render={({ field }) => (
									<FormItem>
										<FormLabel required={false}>{t('phone')}</FormLabel>
										<FormControl>
											<Input {...field} value={field.value ?? ''} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={contactForm.control}
								name="role"
								render={({ field }) => (
									<FormItem>
										<FormLabel required={false}>{t('role')}</FormLabel>
										<FormControl>
											<Input {...field} value={field.value ?? ''} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</Form>
						<DialogFooter>
							<Button
								type="button"
								variant="ghost"
								onClick={() => {
									contactForm.reset(getDefaultContact());
									setContactDialogOpen(false);
								}}
							>
								{t('cancel')}
							</Button>
							<Button type="submit" isLoading={isSubmitting}>
								{editIndex === null ? t('create_contact') : t('save_changes')}
							</Button>
						</DialogFooter>
					</DialogContent>
				</Dialog>
			</form>
		</Page>
	);
}
