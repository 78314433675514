import { LinkButton } from '@/components/buttons/link-button';
import { MutationActionsMenu, MutationDeleteAction, MutationEditAction } from '@/components/dropdown-menus/mutation-actions-menu';
import { trpc } from '@/lib/providers/trpc';
import { useTranslation } from 'react-i18next';
import { DataTable } from '@/components/tables/data-table';
import { DataTableLink } from '@/components/ui/data-table-link';
import { RelativeDate } from '@/components/ui/relative-date';
import { Page, PageActions, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';

export function SysAdminDocumentCategoriesPage() {
	const { data = [], isLoading, error } = trpc.sysadminDocumentCategoriesList.useQuery();
	const { t } = useTranslation();
	const { mutate: deleteCategory } = trpc.sysadminDocumentCategoriesDeleteById.useMutation();

	return (
		<Page isLoading={isLoading} error={error}>
			<PageToolbar>
				<PageTitle>{t('document_categories_title')}</PageTitle>
				<PageActions>
					<LinkButton to="./create">{t('document_category_create')}</LinkButton>
				</PageActions>
			</PageToolbar>

			<PageContent>
				<DataTable
					columnsStorageKey="sys-admin-document-categories-page"
					isLoading={isLoading}
					error={error}
					data={data}
					columns={[
						{
							id: 'name',
							title: t(`category`),
							render(row) {
								return <DataTableLink to={`./${row.id}/edit`}>{row.name}</DataTableLink>;
							},
						},
						{ id: 'createdAt', title: t(`created_at`), render: (row) => <RelativeDate date={row.createdAt} /> },
						{
							id: 'id',
							title: t('actions'),
							actions: true,
							enableSorting: false,
							size: 40,
							render: (row) => {
								return (
									<MutationActionsMenu data-testid={row.name}>
										<MutationEditAction to={`./${row.id}/edit`} />
										<MutationDeleteAction name={row.name} onConfirmDelete={() => deleteCategory({ id: row.id })} />
									</MutationActionsMenu>
								);
							},
						},
					]}
					searchColumn="name"
					defaultSortColumn="name"
				/>
			</PageContent>
		</Page>
	);
}
