import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu';
import { SlidersHorizontal } from 'lucide-react';
import { Table } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu';
import { useTranslation } from 'react-i18next';

interface DataTableViewOptionsProps<TData> {
	table: Table<TData>;
	columnTitles: { id: string; title: string }[];
}

export function DataTableViewOptions<TData>({ table, columnTitles }: DataTableViewOptionsProps<TData>) {
	const { t } = useTranslation();

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="outline" size="sm" className="ml-auto flex h-8">
					<SlidersHorizontal className="mr-2 h-4 w-4" />
					{t('columns')}
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end" className="w-[200px]">
				<DropdownMenuLabel>{t('show_hide_columns')}</DropdownMenuLabel>
				<DropdownMenuSeparator />
				{table
					.getAllColumns()
					.filter((column) => typeof column.accessorFn !== 'undefined' && column.getCanHide())
					.map((column) => {
						return (
							<DropdownMenuCheckboxItem
								key={column.id}
								className="capitalize"
								checked={column.getIsVisible()}
								onCheckedChange={(value) => column.toggleVisibility(!!value)}
								onSelect={(event) => event.preventDefault()}
							>
								{columnTitles.find((c) => c.id === column.id)?.title}
							</DropdownMenuCheckboxItem>
						);
					})}
				<DropdownMenuSeparator />

				<DropdownMenuItem className="flex items-center justify-center text-center" onSelect={() => table.resetColumnVisibility()}>
					<span className="text-center">{t('reset')}</span>
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
}
