import { useChartsList } from './use-charts-list';
import { trpc } from '@/lib/providers/trpc';
import { toast } from 'sonner';
import { cn } from '@/lib/utils';
import { ChartSelect } from './chart-layout';
import { Suspense } from 'react';
import { CenteredSpinner } from '../icons/spinner';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip';

type FrontPageChartIds = ReturnType<typeof useChartsList>[number]['id'];
interface ChartZoneProps<T extends readonly FrontPageChartIds[]> {
	chartOptions: T;
	defaultChart: T[number];
	zoneId: string;
	size: 'sm' | 'md' | 'lg';
}

export function ChartZone<T extends readonly FrontPageChartIds[]>(props: ChartZoneProps<T>) {
	return (
		<div
			className={cn(
				'relative flex w-full flex-col justify-between gap-y-4 rounded-md border border-border p-4',
				props.size === 'sm' && 'h-40',
				props.size === 'md' && 'min-h-72',
				props.size === 'lg' && 'min-h-96',
			)}
		>
			<Suspense fallback={<CenteredSpinner />}>
				<ChartZoneInner {...props} />
			</Suspense>
		</div>
	);
}

function ChartZoneInner<T extends readonly FrontPageChartIds[]>({ chartOptions, zoneId, defaultChart }: ChartZoneProps<T>) {
	const utils = trpc.useUtils();
	const chartsList = useChartsList();

	const [data] = trpc.tenantWidgetById.useSuspenseQuery({
		id: zoneId,
	});

	const chartPositionMutation = trpc.tenantWidgetUpsert.useMutation({
		onMutate: async (input) => {
			// Optimistically update the cache
			await utils.tenantWidgetById.cancel({
				id: zoneId,
			});
			const previousData = utils.tenantWidgetById.getData({ id: zoneId });

			utils.tenantWidgetById.setData(
				{
					id: zoneId,
				},
				(existingData) => {
					// If there was nothing at this position, add the new item
					if (!existingData) {
						return {
							chartId: input.chartId,
							id: zoneId,
						};
					}

					// Something was already pre-selected at this position, so we'll overwrite it
					return {
						...existingData,
						chartId: input.chartId,
					};
				},
			);
			return { previousData };
		},
		onError: (error, _input, context) => {
			utils.tenantWidgetById.setData({ id: zoneId }, context?.previousData);
			toast.error(error.message);
		},
	});

	// Keep only the charts that are available at this zone
	const availableCharts = chartsList.filter((chart) => chartOptions.includes(chart.id));

	// Try to use existing preselected value, otherwise use the first available chart
	const selectedChart = availableCharts.find((v) => v.id === data?.chartId)?.id ?? defaultChart;

	// Find the component for the selected chart
	const SelectedChart = chartsList.find((chart) => chart.id === selectedChart);
	if (!SelectedChart) {
		throw new Error(`No chart found for id: ${selectedChart}`);
	}
	return (
		<>
			<div className="flex items-center justify-between overflow-hidden">
				<ChartSelect
					options={availableCharts.map((chart) => ({
						id: chart.id,
						name: chart.title,
					}))}
					onChange={(chartId) =>
						chartPositionMutation.mutate({
							chartId,
							id: zoneId,
						})
					}
					value={selectedChart as FrontPageChartIds}
				/>
				<TooltipProvider>
					<Tooltip>
						<TooltipTrigger type="button">
							<SelectedChart.icon className="size-5 text-muted-foreground" />
						</TooltipTrigger>
						<TooltipContent className="max-h-96 max-w-96 overflow-auto">{SelectedChart.description}</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			</div>
			<div className="flex flex-1 flex-col" data-testid="chart-zone-component" data-zoneid={zoneId}>
				<SelectedChart.component />
			</div>
		</>
	);
}
