import { trpc } from '@/lib/providers/trpc';
import { useParams } from 'react-router-dom';
import { useTenantId } from './useTenant';

export function useSupplierId() {
	const { supplierId } = useParams();
	return supplierId;
}

export function useSupplierIdSafe() {
	const { supplierId } = useParams();
	return supplierId as string;
}

export function useSupplier() {
	const tenantId = useTenantId();
	const supplierId = useSupplierId();
	return trpc.tenantSuppliersById.useQuery({ id: supplierId!, tenantId: tenantId! }, { enabled: !!supplierId && !!tenantId });
}
