import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { sysadminNotificationsInsertInputSchema } from '@timp/server/src/schemas/sysadmin-notifications-insert.schema';
import { useTranslation } from 'react-i18next';
import { trpc } from '@/lib/providers/trpc';
import { useNavigate } from 'react-router-dom';
import { LinkButton } from '@/components/buttons/link-button';
import { Input } from '@/components/ui/input';
import { Page, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';
import { Textarea } from '@/components/ui/textarea';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Calendar } from '@/components/ui/calendar';
import { addDays, addYears, startOfDay } from 'date-fns';
import { useDateFns } from '@/hooks/useDateFns';
import { CalendarIcon } from 'lucide-react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { RichTextEditor } from '@/components/rich-text-editor/rich-text-editor';

const getDefaulttextContent = () => {
	return [
		{
			type: 'paragraph',
			children: [{ text: '' }],
		},
	];
};

export function SysAdminNotificationsCreatePage() {
	const { format } = useDateFns();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const insertNotification = trpc.sysadminNotificationsInsert.useMutation({
		onSuccess() {
			navigate(`../`);
		},
	});

	const form = useForm<z.infer<typeof sysadminNotificationsInsertInputSchema>>({
		resolver: zodResolver(sysadminNotificationsInsertInputSchema),
		defaultValues: {
			content: {
				type: 'LINK',
				link: undefined,
			},
		},
	});
	const minStartDate = startOfDay(addDays(new Date(), 1));

	const type = useController({
		name: 'content.type',
		control: form.control,
	});
	return (
		<Page size="container">
			<PageToolbar>
				<PageTitle backLink>{t('create_notification')}</PageTitle>
			</PageToolbar>
			<PageContent scroll>
				<Form {...form}>
					<form onSubmit={form.handleSubmit((data) => insertNotification.mutate({ ...data }))} className="space-y-8">
						<FormField
							control={form.control}
							name="title"
							render={({ field }) => (
								<FormItem>
									<FormLabel required={true}>{t('title')}</FormLabel>
									<FormControl>
										<Input {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="summary"
							render={({ field }) => (
								<FormItem>
									<FormLabel required={true}>{t('summary')}</FormLabel>
									<FormControl>
										<Textarea {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="expiresAt"
							render={({ field }) => (
								<FormItem className="flex flex-col">
									<FormLabel required={false}>{t('expires_at')}</FormLabel>
									<Popover>
										<PopoverTrigger asChild>
											<FormControl>
												<Button variant="outline">
													{field.value ? format(new Date(field.value), 'PPP') : null}
													<CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
												</Button>
											</FormControl>
										</PopoverTrigger>
										<PopoverContent className="w-auto p-0" align="start">
											<Calendar
												mode="single"
												selected={field.value ? new Date(field.value) : undefined}
												defaultMonth={new Date(field.value ?? minStartDate)}
												onSelect={(date) => (date ? field.onChange(startOfDay(date).toISOString()) : null)}
												fromDate={field.value ? new Date(Math.min(minStartDate.getTime(), new Date(field.value).getTime())) : minStartDate}
												toDate={addYears(new Date(), 10)}
												initialFocus
												captionLayout="dropdown-buttons"
											/>
										</PopoverContent>
									</Popover>
									<FormMessage />
								</FormItem>
							)}
						/>
						<Tabs value={type.field.value} onValueChange={(val) => type.field.onChange(val)}>
							<TabsList className="my-4 h-auto w-full overflow-x-auto p-2">
								<div className="flex w-full">
									<TabsTrigger value="LINK">{t('LINK')}</TabsTrigger>
									<TabsTrigger value="TEXT">{t('TEXT')}</TabsTrigger>
								</div>
							</TabsList>
							<TabsContent value="LINK">
								<FormField
									control={form.control}
									name="content.link"
									render={({ field }) => (
										<FormItem>
											<FormLabel required={true}>{t('link')}</FormLabel>
											<FormControl>
												<Input {...field} onChange={(e) => field.onChange(e.target.value || undefined)} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</TabsContent>
							<TabsContent value="TEXT">
								<FormField
									control={form.control}
									name="content.textContent"
									render={({ field }) => (
										<FormItem>
											<FormLabel required={true}>{t('TEXT')}</FormLabel>
											<FormControl>
												<RichTextEditor
													disableMentions
													initialValue={(field.value ?? getDefaulttextContent()) as unknown as object}
													onChange={field.onChange}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</TabsContent>
						</Tabs>

						<div className="flex justify-end gap-4">
							<LinkButton variant="outline" to="../">
								{t('cancel')}
							</LinkButton>

							<Button isLoading={insertNotification.isLoading}>{t('create_notification')}</Button>
						</div>
					</form>
				</Form>
			</PageContent>
		</Page>
	);
}
