/* eslint-disable @typescript-eslint/no-explicit-any */
import { RowData, memo } from '@tanstack/react-table';
import type { ColumnFilter, ColumnFiltersState, Row, SortingState, Table as TableType } from '@tanstack/react-table';

export function getServerFacetedUniqueValues<TData extends RowData>(): (table: TableType<TData>, columnId: string) => () => Map<any, number> {
	return (table, columnId) =>
		memo(
			() => [table.getColumn(columnId)?.getFacetedRowModel()],
			(facetedRowModel) => {
				if (!facetedRowModel) return new Map();

				const facetedUniqueValues = new Map<string, number>();

				const initialFilters = table.getColumn(columnId)?.columnDef.meta as { id: string; values: { value: string; count: number }[] };
				if (initialFilters?.values) {
					for (const entry of initialFilters.values) {
						facetedUniqueValues.set(entry.value, entry.count);
					}
				}

				return facetedUniqueValues;
			},
			// {
			// 	key: process.env.NODE_ENV === 'development' && 'getFacetedUniqueValues_' + columnId,
			// 	debug: () => table.options.debugAll ?? table.options.debugTable,
			// 	onChange: () => {},
			// },
			{
				key: 'getFacetedUniqueValues_' + columnId,
			},
		);
}

export function getClientFacetedUniqueValues<TData extends RowData>(): (table: TableType<TData>, columnId: string) => () => Map<any, number> {
	return (table, columnId) =>
		memo(
			() => [table.getColumn(columnId)?.getFacetedRowModel()],
			(facetedRowModel) => {
				if (!facetedRowModel) return new Map();

				const facetedUniqueValues = new Map<string, number>();

				const initialFilters = table.getColumn(columnId)?.columnDef.meta;
				if (Array.isArray(initialFilters)) {
					for (const filter of initialFilters) {
						if (filter?.name) {
							facetedUniqueValues.set(filter.name, 0);
						}
					}
				}

				for (let i = 0; i < facetedRowModel.flatRows.length; i++) {
					const rowValue = facetedRowModel.flatRows[i]!.getValue(columnId)!;
					const values: any = Array.isArray(rowValue) ? (rowValue as string[]) : [rowValue];
					for (const val of values) {
						if (facetedUniqueValues.has(val)) {
							facetedUniqueValues.set(val, (facetedUniqueValues.get(val) ?? 0) + 1);
						} else {
							facetedUniqueValues.set(val, 1);
						}
					}
				}

				return facetedUniqueValues;
			},
			// {
			// 	key: process.env.NODE_ENV === 'development' && 'getFacetedUniqueValues_' + columnId,
			// 	debug: () => table.options.debugAll ?? table.options.debugTable,
			// 	onChange: () => {},
			// },
			{
				key: 'getFacetedUniqueValues_' + columnId,
			},
		);
}

export function filterFn<TData>(row: Row<TData>, id: string, filtersValue: any): boolean {
	const rowValue = row.getValue(id);
	const rowValueIsArray = Array.isArray(rowValue);
	const filtersValueIsArray = Array.isArray(filtersValue);

	// filter on a column with multiple values
	if (filtersValueIsArray && rowValueIsArray) {
		const s1 = new Set(rowValue as any[]);
		for (const v of filtersValue) {
			if (s1.has(v)) {
				return true;
			}
		}

		return false;
	}

	// filter on a column with simple value
	if (filtersValueIsArray && !rowValueIsArray) {
		return filtersValue.includes(rowValue);
	}

	// search on a column with multiple values
	if (!filtersValueIsArray && rowValueIsArray) {
		for (const v of rowValue) {
			if (v?.toString()?.toLowerCase()?.includes(filtersValue?.toString()?.toLowerCase())) {
				return true;
			}
		}

		return false;
	}

	// search on a simple column
	if (!filtersValueIsArray && !rowValueIsArray) {
		return !!rowValue?.toString()?.toLowerCase()?.includes(filtersValue?.toString()?.toLowerCase());
	}

	return false;
}

interface ParamsUrlReturn {
	page: number;
	sort: SortingState | undefined;
	search: string | null;
	filters: ColumnFiltersState | undefined;
	timeFilters: ColumnTimeFilterState[] | undefined;
	filterOrder: string[] | undefined;
}

export function parseURLFiltersIntoServerTableFilters(url: string): ParamsUrlReturn | undefined {
	const urlParams = new URLSearchParams(url);
	const page = urlParams.get('page');
	const sort = urlParams.get('sort');
	const search = urlParams.get('search');
	const filters = urlParams.get('filters');
	const timeFilters = urlParams.get('timeFilters');
	const filterOrder = urlParams.get('filterOrder');
	try {
		return {
			page: parseInt(page!) || 0,
			sort: sort ? JSON.parse(decodeURIComponent(sort)) : undefined,
			search: search,
			filters: filters ? JSON.parse(decodeURIComponent(filters)) : undefined,
			timeFilters: timeFilters ? JSON.parse(decodeURIComponent(timeFilters)) : undefined,
			filterOrder: filterOrder ? JSON.parse(decodeURIComponent(filterOrder)) : undefined,
		};
	} catch (e) {
		console.error(e);
	}
}

export type ColumnTimeFilterState = {
	id: string;
	fromTime?: string | null;
	toTime?: string | null;
};

export function parseServerTableFiltersIntoURLParams({
	pageIndex,
	sorting,
	search,
	filters,
	timeFilters,
	filterOrder,
}: {
	pageIndex: number;
	sorting: SortingState;
	search: string;
	filters: (ColumnFilter | undefined)[];
	timeFilters: (ColumnTimeFilterState | undefined)[];
	filterOrder: string[] | undefined;
}) {
	const params = new URLSearchParams({
		page: pageIndex.toString(),
		sort: encodeURIComponent(JSON.stringify(sorting)),
		search: search,
		filters: encodeURIComponent(JSON.stringify(filters.filter((v) => !!v))),
		timeFilters: encodeURIComponent(JSON.stringify(timeFilters.filter((v) => !!v))),
		filterOrder: encodeURIComponent(JSON.stringify(filterOrder)),
	});
	return params;
}
