import { RouterOutput } from '@/lib/providers/trpc';
import { useTranslation } from 'react-i18next';
import { CheckCircle2Icon, ClockIcon } from 'lucide-react';

export type InspectionStatus = RouterOutput['tenantInspectionsList']['rows'][number]['status'];

interface Props {
	status: InspectionStatus;
}

export function InspectionStatusIconLabel({ status }: Props) {
	const { t } = useTranslation();
	switch (status) {
		case 'PROCESSED':
			return (
				<span className="flex flex-row items-center gap-x-1.5">
					<CheckCircle2Icon strokeWidth={1.5} className="size-[18px] text-green-700" /> {t(`${status}`)}
				</span>
			);
		case 'UNPROCESSED':
			return (
				<span className="flex flex-row items-center gap-x-1.5">
					<ClockIcon strokeWidth={1.5} className="size-[18px] text-gray-700" /> {t(`${status}`)}
				</span>
			);
	}
}
