import { CaseCommentSection, CaseDeviationListSection, CaseTimelimeHistorySection } from '@/pages/app-main/cases/case-details-components';
import type { RouterOutput } from '@/lib/providers/trpc';
import { useTranslation } from 'react-i18next';
import { TypographyH1, TypographyH2, TypographyMuted, TypographySmall } from '../ui/typography';
import { useDateFns } from '@/hooks/useDateFns';
import { PrintFormViewer } from '../form-viewers/print-form-viewer';

interface CaseDataProps {
	leCase: RouterOutput['tenantCasesById'];
	timeline: RouterOutput['tenantCasesTimelineByCaseId'];
}

const InfoItem = ({ label, value }: { label: string; value: string }) => (
	<div className="flex flex-col gap-y-2">
		<TypographyMuted className="text-sm font-medium text-muted-foreground">{label}</TypographyMuted>
		<TypographySmall className="text-base">{value}</TypographySmall>
	</div>
);

export function RenderCasePdfContent({ leCase, timeline }: CaseDataProps) {
	const { t } = useTranslation();
	const { format } = useDateFns();

	return (
		<>
			<div className="flex min-h-screen flex-col items-center justify-center p-8 print:break-after-page">
				<img src="https://app.timp.no/logo.png" alt="TIMP Logo" className="mb-8 w-32" />
				<TypographyH1 className="mb-4 text-3xl font-bold">{t('case_summary_report')}</TypographyH1>
				<TypographyH2 className="mb-8 text-xl text-muted-foreground">{leCase.title}</TypographyH2>

				<div className="w-full max-w-3xl rounded-md border bg-white p-8">
					<div className="grid grid-cols-2 gap-y-8">
						<InfoItem label={t('supplier')} value={leCase.supplierName} />
						<InfoItem label={t('form_template')} value={leCase.formTemplateName} />
						<InfoItem label={t('status')} value={t(leCase.status)} />
						<InfoItem label={t('response_deadline')} value={format(new Date(leCase.responseDeadline), 'PPP')} />
						<InfoItem label={t('created_by')} value={leCase.createdByName || '-'} />
						<InfoItem label={t('created_at')} value={format(new Date(leCase.createdAt), 'PPP')} />
						<InfoItem label={t('processed_by')} value={leCase.processedByName || '-'} />
						<InfoItem label={t('last_activity')} value={format(new Date(leCase.lastActivityAt), 'PPP')} />
					</div>
				</div>
			</div>

			<div className="print:break-after-page">
				<CaseTimelimeHistorySection timeline={timeline} />
			</div>
			<div className="flex flex-col gap-y-8 print:break-after-page">
				<CaseCommentSection leCase={leCase} />
				<CaseDeviationListSection leCase={leCase} />
			</div>
			<PrintFormViewer form={leCase.formContent} />
		</>
	);
}
