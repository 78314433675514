import { formTemplatesCommonBuildFormContentInputSchema } from './form-templates-common.schema';
import * as z from 'zod';

export const tenantCasesInsertInputSchema = z.object({
	title: z.string(),
	supplierIds: z.array(z.string().uuid()).min(1),
	formContent: formTemplatesCommonBuildFormContentInputSchema,
	formTemplateId: z.string().uuid(),
	autoProcessOnResponse: z.boolean(),
	autoProcessOnDeadline: z.boolean(),
	message: z.string().optional(),
});

export const tenantCasesInsertOutputSchema = z.array(z.string().uuid()).min(1);
