import { LinkButton } from '@/components/buttons/link-button';
import { DataTable } from '@/components/tables/data-table';
import { MutationActionsMenu, MutationDeleteAction, MutationEditAction } from '@/components/dropdown-menus/mutation-actions-menu';
import { RelativeDate } from '@/components/ui/relative-date';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { useUser } from '@/hooks/useUser';
import { RouterOutput, trpc } from '@/lib/providers/trpc';
import { useTranslation } from 'react-i18next';
import { DataTableLink } from '@/components/ui/data-table-link';
import { Page, PageActions, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';

type Role = RouterOutput['tenantUsersList'][number]['role'];

export function TenantSettingsUsersPage() {
	const user = useUser();
	const tenantId = useTenantIdSafe();

	const { t } = useTranslation();
	const { data = [], isLoading, error } = trpc.tenantUsersList.useQuery({ tenantId });

	const { mutate: deleteUser } = trpc.tenantUsersDeleteById.useMutation();

	return (
		<Page isLoading={isLoading} error={error}>
			<PageToolbar>
				<PageTitle>{t('users_title')}</PageTitle>
				<PageActions>
					<LinkButton to="./create">{t('create')}</LinkButton>
				</PageActions>
			</PageToolbar>

			<PageContent>
				<DataTable
					columnsStorageKey="tenant-settings-users-page"
					isLoading={isLoading}
					error={error}
					data={data}
					columns={[
						{
							id: 'fullName',
							title: t(`full_name`),
							render: (row) => {
								if (row.userId === user.id) {
									// Don't allow users to delete/update themselves
									return <span>{row.fullName}</span>;
								}
								return <DataTableLink to={`./${row.userId}/edit`}>{row.fullName ?? t('invitation_sent')}</DataTableLink>;
							},
						},
						{
							id: 'email',
							title: t(`email`),
							render: (row) => (
								<a href={`mailto:${row.email}`} className="underline">
									{row.email}
								</a>
							),
						},
						{
							id: 'role',
							title: t('role'),
							filter: true,
							render: (row) => t(`${row.role}`),
							valueToLabel: (role: Role) => t(`${role}`),
						},
						{ id: 'createdAt', title: t(`created_at`), render: (row) => <RelativeDate date={row.createdAt} /> },
						{
							id: 'userId',
							title: t('actions'),
							actions: true,
							enableSorting: false,
							size: 40,
							render: (row) => {
								if (row.userId === user.id) return null; // Don't allow users to delete/update themselves
								return (
									<MutationActionsMenu>
										<MutationEditAction to={`./${row.userId}/edit`} />
										<MutationDeleteAction name={row.email} onConfirmDelete={() => deleteUser(row)} />
									</MutationActionsMenu>
								);
							},
						},
					]}
					searchColumn="fullName"
					defaultSortColumn="fullName"
				/>
			</PageContent>
		</Page>
	);
}
