import { LinkButton } from '@/components/buttons/link-button';
import { MutationActionsMenu, MutationDeleteAction } from '@/components/dropdown-menus/mutation-actions-menu';
import { DeviationSeverityIconLabel } from '@/components/labels/deviation-severity-icon-label';
import { Page, PageActions, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';
import { ServerDataTable } from '@/components/tables/server-data-table';
import { DataTableLink } from '@/components/ui/data-table-link';
import { RelativeDate } from '@/components/ui/relative-date';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { trpc } from '@/lib/providers/trpc';
import { useTranslation } from 'react-i18next';

export function DeviationsPage() {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();

	const { mutate: deleteDeviation } = trpc.tenantDeviationsDeleteById.useMutation();

	return (
		<Page>
			<PageToolbar>
				<PageTitle>{t('deviations')}</PageTitle>

				<PageActions>
					<LinkButton to={`./create`}>{t('create_deviation')}</LinkButton>
				</PageActions>
			</PageToolbar>

			<PageContent>
				<ServerDataTable
					columnsStorageKey="tenant-deviations-page"
					query={trpc.tenantDeviationsList}
					params={{
						tenantId,
					}}
					columns={[
						{
							id: 'supplierName',
							title: t('supplier'),
							enableSorting: true,
							filter: true,

							render(row) {
								return (
									<DataTableLink className="text-nowrap" to={`../suppliers/${row.supplierId}/deviations`}>
										{row.supplierName}
									</DataTableLink>
								);
							},
						},
						{
							id: 'severity',
							title: t('severity'),
							filter: true,
							enableSorting: false,
							render(row) {
								return <DeviationSeverityIconLabel severity={row.severity} />;
							},
							valueToLabel: (type) => t(type),
						},
						{
							id: 'description',
							title: t('description'),
							enableSorting: false,
							render(row) {
								return (
									<DataTableLink className="line-clamp-1" to={`./${row.id}`}>
										{row.description}
									</DataTableLink>
								);
							},
						},
						{
							id: 'type',
							title: t('type'),
							filter: true,
							enableSorting: false,
							render(row) {
								return t(row.type);
							},
							valueToLabel: (type) => t(type),
						},
						{
							id: 'createdAt',
							title: t('created_at'),
							timeFilter: true,
							render: (row) => <RelativeDate date={row.createdAt} />,
						},
						{
							id: 'id',
							title: t('actions'),
							actions: true,
							enableSorting: false,
							size: 40,
							render: (row) => (
								<MutationActionsMenu>
									<MutationDeleteAction onConfirmDelete={() => deleteDeviation({ id: row.id, tenantId })} />
								</MutationActionsMenu>
							),
						},
					]}
					searchColumns={['description']}
					defaultSortColumn="createdAt"
					paginationPageSize={50}
					defaultSortOrder="desc"
				/>
			</PageContent>
		</Page>
	);
}
