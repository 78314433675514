import { Page, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';
import { ServerDataTable } from '@/components/tables/server-data-table';
import { DataTableLink } from '@/components/ui/data-table-link';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { trpc } from '@/lib/providers/trpc';
import { useTranslation } from 'react-i18next';

export function DocumentsMissingTasksPage() {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();
	return (
		<Page>
			<PageToolbar>
				<PageTitle>{t('missing_documents')}</PageTitle>
			</PageToolbar>
			<PageContent>
				<ServerDataTable
					columnsStorageKey="documents-missing-tasks-page"
					query={trpc.tenantTasksDocumentsMissingList}
					params={{
						tenantId,
					}}
					columns={[
						{
							id: 'documentCategoryName',
							title: t(`category`),
							filter: true,
							render: (row) => {
								const description = t('documents_missing_task_description', { supplierName: row.supplierName, documentCategoryName: row.documentCategoryName });
								return (
									<DataTableLink
										to={`/tenants/${tenantId}/suppliers/${row.supplierId}/documents/create?documentCategoryId=${row.documentCategoryId}&name=${row.documentCategoryName}&locked=true`}
									>
										{description}
									</DataTableLink>
								);
							},
						},
						{
							id: 'supplierName',
							title: t(`supplier`),
							filter: true,
							enableSorting: true,
							render: (row) => {
								return <DataTableLink to={`/tenants/${tenantId}/suppliers/${row.supplierId}`}>{row.supplierName}</DataTableLink>;
							},
						},
					]}
					defaultSortColumn="supplierName"
					defaultSortOrder="asc"
					paginationPageSize={50}
				/>
			</PageContent>
		</Page>
	);
}
