import { TypographyBlank, TypographyH4 } from '../ui/typography';
import { ChartContentLayout } from './chart-layout';
import { trpc } from '@/lib/providers/trpc';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { useSupplierId } from '@/hooks/useSupplier';
import { SupplierStatusIconLabel } from '../labels/supplier-status-icon-label';
import { useTranslation } from 'react-i18next';

export function TextChartSupplierStatus() {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();
	const supplierId = useSupplierId();

	const query = trpc.tenantWidgetSupplierStatus.useQuery(
		{
			tenantId,
			supplierId: supplierId!,
		},
		{
			enabled: !!supplierId,
		},
	);
	let customError: Error | null = null;

	// Prevent devs from using this chart on pages where it's not supported
	if (!supplierId) {
		customError = new Error(t('chart_not_supported_on_this_page'));
	}

	return (
		<ChartContentLayout error={customError ?? query.error} isLoading={query.isLoading}>
			{query.data && (
				<TypographyH4>
					<SupplierStatusIconLabel status={query.data.status} />
				</TypographyH4>
			)}
			<TypographyBlank />
		</ChartContentLayout>
	);
}
