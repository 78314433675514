import * as z from 'zod';

export const tenantProjectsUpdateByIdInputSchema = z.object({
	id: z.string().uuid(),
	name: z.string().min(1).max(250).optional(),
	supplierIds: z.array(z.string().uuid()).optional(),
});

export const tenantProjectsUpdateByIdOutputSchema = z.object({
	id: z.string().uuid(),
});
