import { Trans, useTranslation } from 'react-i18next';
import { RouterOutput, trpc } from '@/lib/providers/trpc';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { Link } from '@/components/links/Link';
import { TypographyLabel, TypographyMuted } from '@/components/ui/typography';
import { CheckCircle, Circle, Mail, MailOpen, MessageSquare, PlusCircle, XCircle } from 'lucide-react';
import { Spinner } from '@/components/icons/spinner';
import { RelativeDate } from '@/components/ui/relative-date';
import { CaseContactReminderStatusIconLabel } from '@/components/labels/case-contact-reminder-status-icon-label';
import { Timeline, TimelineItem } from '@/components/ui/timeline';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { useDateFns } from '@/hooks/useDateFns';
import { Button } from '@/components/ui/button';
import { useMemo, useState } from 'react';
import { Separator } from '@/components/ui/separator';
import { FormViewer } from '@/components/form-viewers/form-viewer';
import { DeviationSeverityIconLabel } from '@/components/labels/deviation-severity-icon-label';

type OriginalTimelineEvents = RouterOutput['tenantCasesTimelineByCaseId'][number];
type TimelineEvent =
	| Exclude<OriginalTimelineEvents, { type: 'EMAIL' | 'OPENED' }>
	| {
			type: 'EMAILS';
			timestamp?: undefined;
			emails: {
				contact: {
					id: string;
					fullName: string;
				};
				timestamp: string;
				isInvalidated: boolean;
				status: Pick<Extract<OriginalTimelineEvents, { type: 'EMAIL' }>, 'status'>['status'];
			}[];
	  }
	| {
			type: 'OPENINGS';
			timestamp?: undefined;
			openings: {
				contact: {
					id: string;
					fullName: string;
				} | null;
				timestamp: string;
			}[];
	  };

export function CaseTimelimeHistorySection({ timeline }: { timeline: RouterOutput['tenantCasesTimelineByCaseId'] }) {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();

	const timelineItems = useMemo(() => {
		const result: TimelineEvent[] = [];
		let currentEmailGroup: TimelineEvent | null = null;
		let currentOpeningGroup: TimelineEvent | null = null;

		for (const event of timeline) {
			if (event.type === 'EMAIL') {
				if (currentEmailGroup && currentEmailGroup.type === 'EMAILS') {
					currentEmailGroup.emails.push(event);
				} else {
					currentEmailGroup = {
						type: 'EMAILS',
						emails: [event],
					};
					result.push(currentEmailGroup);
				}
				currentOpeningGroup = null;
			} else if (event.type === 'OPENED') {
				if (currentOpeningGroup && currentOpeningGroup.type === 'OPENINGS') {
					currentOpeningGroup.openings.push(event);
				} else {
					currentOpeningGroup = {
						type: 'OPENINGS',
						openings: [event],
					};
					result.push(currentOpeningGroup);
				}
				currentEmailGroup = null;
			} else {
				result.push(event);
				currentEmailGroup = null;
				currentOpeningGroup = null;
			}
		}

		return result;
	}, [timeline]);

	function getTitle(entry: TimelineEvent) {
		const type = entry.type;
		switch (type) {
			case 'CREATED': {
				return (
					<span className="flex items-center gap-x-1">
						<Trans i18nKey="case_created" values={{ name: entry.user?.fullName ?? `${t('system')}`.toUpperCase() }} />
						{entry.automaticCaseId && (
							<Link
								className="text-sm font-normal text-gray-500 hover:underline"
								to={`/tenants/${tenantId}/settings/automatic-cases/${entry.automaticCaseId}/edit`}
							>
								({t('automatic_case')})
							</Link>
						)}
					</span>
				);
			}
			case 'EMAILS':
				return <Trans i18nKey="case_sent_to_contact" />;
			case 'OPENINGS':
				return <Trans i18nKey="case_opened_by_title" />;
			case 'ANSWERED':
				return <Trans i18nKey="case_answered_by_supplier" values={{ name: entry.contact?.fullName ?? `${t('unknown_person')}`.toLowerCase() }} />;
			case 'PROCESSED':
				return <Trans i18nKey="case_processed_approved" values={{ name: entry.user?.fullName ?? t('system') }} />;
			case 'RESPONSE_REJECTED':
				return <Trans i18nKey="case_processed_rejected" values={{ name: entry.user?.fullName ?? t('system') }} />;
			default: {
				const _exhaustiveCheck: never = type;
			}
		}
		return '';
	}
	function getDescription(entry: TimelineEvent) {
		const type = entry.type;
		switch (type) {
			case 'CREATED':
				return entry.description;
			case 'PROCESSED':
				return entry.description;
			case 'EMAILS':
				return (
					<div className="flex flex-col gap-y-1.5">
						{entry.emails.map((email, index) => (
							<span key={index} className="flex items-center gap-x-1 text-nowrap text-sm text-muted-foreground">
								<CaseContactReminderStatusIconLabel status={email.status} contactFullName={email.contact.fullName} className="mr-1 size-4" />
								<Trans i18nKey="case_sent_to_supplier" values={{ name: email.contact.fullName }} />
								<RelativeDate date={email.timestamp} />
							</span>
						))}
					</div>
				);
			case 'OPENINGS':
				return (
					<div className="flex flex-col gap-y-1.5">
						{entry.openings.map((opening, index) => (
							<span key={index} className="flex items-center gap-x-1 text-nowrap text-sm text-muted-foreground">
								<Trans i18nKey="case_opened_by_supplier" values={{ name: opening.contact?.fullName ?? t('unknown_person') }} />
								<RelativeDate date={opening.timestamp} />
							</span>
						))}
					</div>
				);
			case 'RESPONSE_REJECTED':
				return entry.description;
			case 'ANSWERED':
				return <PreviewCaseResponseButton caseResponseId={entry.caseResponseId} />;
		}
		return null;
	}

	function getIcon(entry: TimelineEvent) {
		const type = entry.type;
		switch (type) {
			case 'CREATED':
				return <PlusCircle className="size-6 text-primary" strokeWidth={2} />;
			case 'EMAILS':
				return <Mail className="size-6 text-primary" strokeWidth={2} />;
			case 'OPENINGS':
				return <MailOpen className="size-6 text-primary" strokeWidth={2} />;
			case 'ANSWERED':
				return <MessageSquare className="size-6 text-primary" strokeWidth={2} />;
			case 'PROCESSED':
				return <CheckCircle className="size-6 text-primary" strokeWidth={2} />;
			case 'RESPONSE_REJECTED':
				return <XCircle className="size-6 text-primary" strokeWidth={2} />;
			default:
				return <Circle className="size-6 text-primary" strokeWidth={2} />;
		}
	}

	return (
		<div className="flex flex-col gap-y-2 overflow-x-auto">
			<div className="flex gap-x-2">
				<TypographyLabel className="text-lg">{t('timeline')}</TypographyLabel>
			</div>
			{timelineItems.length && (
				<Timeline>
					{timelineItems.map((item, index) => {
						return (
							<TimelineItem
								key={index}
								date={item?.timestamp}
								title={getTitle(item)}
								description={getDescription(item)}
								icon={getIcon(item)}
								isFirst={index === 0}
								isLast={index === timelineItems.length - 1}
							/>
						);
					})}
				</Timeline>
			)}
		</div>
	);
}

export function PreviewCaseResponseButton({ caseResponseId }: { caseResponseId: string }) {
	const { t } = useTranslation();
	const { format } = useDateFns();
	const tenantId = useTenantIdSafe();
	const [open, setOpen] = useState(false);
	const { data, isLoading } = trpc.tenantCasesResponseById.useQuery(
		{ id: caseResponseId, tenantId },
		{
			enabled: open,
		},
	);
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button size="sm" variant="outline" className="h-6 text-xs print:hidden">
					{t('preview_case_response')}
				</Button>
			</DialogTrigger>
			<DialogContent size="full">
				{data?.createdAt && (
					<DialogHeader>
						<DialogTitle className="mb-2">
							{t('case_response_from', {
								date: format(data.createdAt, 'P'),
							})}
						</DialogTitle>
						<Separator />
					</DialogHeader>
				)}
				<div className="flex flex-1 gap-x-4 overflow-hidden">
					{isLoading && <Spinner />}
					{data && <FormViewer form={data.formContent} mode="answers-view" />}
				</div>
			</DialogContent>
		</Dialog>
	);
}

export function CaseDeviationListSection({ leCase }: { leCase: RouterOutput['tenantCasesById'] }) {
	const { t } = useTranslation();
	return (
		<div className="flex flex-col gap-y-2 overflow-x-auto" data-testid="deviation-list">
			<TypographyLabel className="text-lg">{t('deviations')}</TypographyLabel>
			{leCase.deviations.length > 0 ? (
				<ul className="flex flex-col gap-y-2">
					{leCase.deviations.map((deviation) => (
						<li key={deviation.id} className="flex flex-col gap-y-2 rounded-md bg-muted p-3">
							<DeviationSeverityIconLabel severity={deviation.severity} />
							<TypographyMuted>{deviation.description}</TypographyMuted>
						</li>
					))}
				</ul>
			) : (
				<TypographyMuted>{t('no_deviations')}</TypographyMuted>
			)}
		</div>
	);
}

export function CaseCommentSection({ leCase }: { leCase: RouterOutput['tenantCasesById'] }) {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col gap-y-2">
			<TypographyLabel className="text-lg">{t('comment')}</TypographyLabel>
			<div className="flex min-h-24 basis-full flex-col gap-y-2 rounded-md bg-muted p-3">
				<TypographyMuted>{leCase.processedComment}</TypographyMuted>
			</div>
		</div>
	);
}
