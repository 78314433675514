import { trpc } from '@/lib/providers/trpc';
import { ListChecksIcon } from 'lucide-react';
import { NavigationNavLink } from '../buttons/navigation-link';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip';
import { useTranslation } from 'react-i18next';

interface Props {
	tenantId: string;
}
export function TasksMenu({ tenantId }: Props): JSX.Element {
	const { t } = useTranslation();
	const tasksQuery = trpc.tenantTasksCount.useQuery(
		{ tenantId: tenantId! },
		{
			enabled: !!tenantId,
			refetchInterval: 1000 * 60, // 1 minute
		},
	);
	const tasksCount = tasksQuery.data ? Object.values(tasksQuery.data).reduce((acc, curr) => acc + curr, 0) : 0;
	const tasks = tasksQuery.data;
	let tasksUrl = `/tenants/${tenantId}/tasks`;
	if (tasks) {
		if (tasks.CASES_RESPONSE > 0) {
			tasksUrl = `/tenants/${tenantId}/tasks/case-response`;
		} else if (tasks.CASES_NO_RESPONSE > 0) {
			tasksUrl = `/tenants/${tenantId}/tasks/case-noresponse`;
		} else if (tasks.INSPECTIONS_UNPROCESSED > 0) {
			tasksUrl = `/tenants/${tenantId}/tasks/inspection-unprocessed`;
		} else if (tasks.INSPECTIONS_MISSING > 0) {
			tasksUrl = `/tenants/${tenantId}/tasks/inspection-missing`;
		} else if (tasks.DOCUMENTS_MISSING > 0) {
			tasksUrl = `/tenants/${tenantId}/tasks/document-missing`;
		} else if (tasks.DOCUMENTS_EXPIRED > 0) {
			tasksUrl = `/tenants/${tenantId}/tasks/document-expired`;
		}
	}

	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger type="button">
					<NavigationNavLink to={tasksUrl} className="relative" data-testid="task-menu">
						<ListChecksIcon className="size-5" />
						{tasksCount > 99 && (
							<div className="absolute left-5 top-0 flex h-4 w-7 items-center justify-center rounded-full bg-blue-600 text-background/60 dark:text-primary/70">
								<span className="text-[10px] font-bold">99+</span>
							</div>
						)}
						{tasksCount > 9 && tasksCount < 100 && (
							<div className="absolute left-5 top-0 flex h-4 w-5 items-center justify-center rounded-full bg-blue-600 text-background/60 dark:text-primary/70">
								<span className="text-[10px] font-bold">{tasksCount}</span>
							</div>
						)}
						{tasksCount > 0 && tasksCount < 10 && (
							<div className="absolute right-0 top-0 flex size-4 items-center justify-center rounded-full bg-blue-600 text-background/60 dark:text-primary/70">
								<span className="text-[10px] font-bold">{tasksCount}</span>
							</div>
						)}
					</NavigationNavLink>
				</TooltipTrigger>
				<TooltipContent className="max-h-96 max-w-96 overflow-auto">{t('tasks')}</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);
}
