import { UnsavedChangesBlocker } from '@/components/dialogs/unsaved-changes-blocker';
import { FormBuilder } from '@/components/form-builders/form-builder';
import { Page, PageTitle, PageToolbar, PageContent } from '@/components/layouts/page';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormDescription } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';
import { trpc } from '@/lib/providers/trpc';
import { zodResolver } from '@hookform/resolvers/zod';
import { sysAdminFormTemplatesCommonInsertInput } from '@timp/server/src/schemas/form-templates-common.schema';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { z } from 'zod';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { LinkButton } from '@/components/buttons/link-button';
import { SelectSingleSysadminFormTemplate } from '@/components/selects/select-single';

export function SysAdminCaseFormsCreatePage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [dialogOpen, setDialogOpen] = useState(false);

	const insertMutation = trpc.sysadminFormTemplatesInsert.useMutation({
		onSuccess() {
			navigate('../');
		},
	});

	const form = useForm<z.infer<typeof sysAdminFormTemplatesCommonInsertInput>>({
		resolver: zodResolver(sysAdminFormTemplatesCommonInsertInput),
		defaultValues: {
			type: 'CASE',
			published: false,
			formContent: {
				sections: [],
			},
		},
	});

	const copiedFromId = form.watch('copiedFromId');
	const copiedTemplateQuery = trpc.sysadminFormTemplatesById.useQuery({ id: copiedFromId! }, { enabled: !!copiedFromId });

	useEffect(() => {
		if (!copiedFromId) {
			form.setValue('formContent', { sections: [] });
		} else if (copiedTemplateQuery.data) {
			form.setValue('formContent', copiedTemplateQuery.data?.formContent);
		}
	}, [copiedTemplateQuery.data, form, copiedFromId]);

	const hasUnsavedChanges = !!form.formState.dirtyFields.formContent;

	return (
		<Page className="overflow-x-auto overflow-y-hidden">
			<Form {...form}>
				<PageToolbar>
					<PageTitle backLink>{t('create_case_form')}</PageTitle>
				</PageToolbar>

				<PageContent>
					<div className="space-y-6">
						<FormField
							control={form.control}
							name="name"
							render={({ field }) => (
								<FormItem>
									<FormLabel required={true}>{t('form_name')}</FormLabel>
									<FormControl>
										<Input {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="copiedFromId"
							render={({ field }) => (
								<FormItem>
									<FormLabel required>{t('form_content')}</FormLabel>
									<div className="flex w-full items-center gap-x-2">
										<SelectSingleSysadminFormTemplate treatNullAsEmptyOption onChange={field.onChange} value={field.value} type="CASE" />
										<Button
											type="button"
											onClick={() => {
												setDialogOpen(true);
											}}
										>
											{t('edit_form')}
										</Button>
									</div>
									<FormDescription>{t('create_form_template_description')}</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="published"
							render={({ field }) => (
								<FormItem className="flex flex-col">
									<div className="flex items-center gap-x-2">
										<FormControl>
											<Checkbox checked={field.value} onCheckedChange={field.onChange} name={field.name} />
										</FormControl>
										<FormLabel required={false}>{t('published')}</FormLabel>
									</div>
									<FormDescription>{t('sysadmin_form_published_description')}</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<div className="flex justify-end gap-4">
							<LinkButton variant="outline" to="../">
								{t('cancel')}
							</LinkButton>

							<Button
								isLoading={insertMutation.isLoading}
								type="button"
								onClick={() =>
									form.handleSubmit(
										(formData) => {
											insertMutation.mutate(formData);
										},
										(errors) => {
											if (Object.keys(errors.formContent ?? {}).length > 0) {
												setDialogOpen(true);
											}
										},
									)()
								}
							>
								{t('form_create')}
							</Button>
						</div>
					</div>

					<Dialog
						open={dialogOpen}
						onOpenChange={(open) => {
							setDialogOpen(open);
						}}
					>
						<DialogContent size="full">
							<FormBuilder />
						</DialogContent>
					</Dialog>
				</PageContent>

				<UnsavedChangesBlocker hasUnsavedChanges={hasUnsavedChanges && insertMutation.isIdle} />
			</Form>
		</Page>
	);
}
