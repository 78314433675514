import React, { useState } from 'react';
import { Button, ButtonProps } from '../ui/button';
import { useTranslation } from 'react-i18next';
import { CopyIcon } from 'lucide-react';
import { toast } from 'sonner';
import { cn } from '@/lib/utils';

interface CopyButtonProps extends Pick<ButtonProps, 'variant' | 'size'> {
	text: string; // Text to be copied to the clipboard
	copyType?: 'default' | 'link';
}

export function CopyButton({ text, copyType = 'default', variant = 'default', size = 'sm', ...props }: CopyButtonProps) {
	const { t } = useTranslation();
	const [isDisabled, setIsDisabled] = useState<boolean>(false);

	// Function to copy the text
	function copyText() {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				if (copyType === 'link') {
					toast.success(t('link_copied_to_clipboard'));
				} else if (copyType === 'default') {
					toast.success(t('copied_to_clipboard'));
				}
			})
			.catch((err) => {
				toast.error(t('failed_to_copy_to_clipboard'), { description: err.message });
			});
	}

	function handleClick() {
		copyText();
		setIsDisabled(true);
		// Set a timer to re-enable the button after 2 seconds
		setTimeout(() => setIsDisabled(false), 2000);
	}

	return (
		<Button className={cn('min-w-28 justify-start')} {...props} onClick={handleClick} disabled={isDisabled} size={size} variant={variant} type="button">
			<CopyIcon className="size-5" />
			{isDisabled ? (
				<span>{t('copied')}</span>
			) : (
				<span>
					{copyType === 'link' && t('copy_link')}
					{copyType === 'default' && t('copy')}
				</span>
			)}
		</Button>
	);
}
