import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { RouteMenuLink } from './route-menu-link';
import { Building2Icon, VariableIcon, NotepadTextIcon, Users2Icon, BellIcon, FoldersIcon, MenuIcon } from 'lucide-react';
import { TypographyH5, TypographyP } from '../ui/typography';
import { PageSidebar } from './page';
import { useSysAdminGuard } from '@/hooks/useUser';
import { useState } from 'react';
import { Button } from '../ui/button';

export function SysAdminLayout() {
	const { t } = useTranslation();
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);

	useSysAdminGuard();

	return (
		<div className="flex h-full w-full overflow-hidden">
			<PageSidebar open={isSidebarOpen} setOpen={setIsSidebarOpen}>
				<div className="flex flex-col gap-y-1">
					<TypographyH5 className="line-clamp-2 text-background/75 dark:text-primary/50">{t('admin_panel_title')}</TypographyH5>

					<TypographyP className="text-background/50 dark:text-primary/50">{t('general')}</TypographyP>
					<RouteMenuLink to="/sysadmin/tenants">
						<Building2Icon className="h-4 w-4 text-background/80 dark:text-primary/60" />
						{t('companies')}
					</RouteMenuLink>
					<RouteMenuLink to="/sysadmin/users">
						<Users2Icon className="h-4 w-4 text-background/80 dark:text-primary/60" />
						{t('users')}
					</RouteMenuLink>
					<RouteMenuLink to="/sysadmin/system-variables">
						<VariableIcon className="h-4 w-4 text-background/80 dark:text-primary/60" />
						{t('system_variables')}
					</RouteMenuLink>
					<RouteMenuLink to="/sysadmin/notifications">
						<BellIcon className="h-4 w-4 text-background/80 dark:text-primary/60" />
						{t('notifications')}
					</RouteMenuLink>
				</div>

				<div className="flex flex-col gap-y-1">
					<TypographyP className="text-background/50 dark:text-primary/50">{t('inspections')}</TypographyP>
					<RouteMenuLink to="/sysadmin/inspection-forms">
						<NotepadTextIcon className="h-4 w-4 text-background/80 dark:text-primary/60" />
						{t('inspection_forms')}
					</RouteMenuLink>
				</div>

				<div className="flex flex-col gap-y-1">
					<TypographyP className="text-background/50 dark:text-primary/50">{t('cases')}</TypographyP>
					<RouteMenuLink to="/sysadmin/case-forms">
						<NotepadTextIcon className="h-4 w-4 text-background/80 dark:text-primary/60" />
						{t('case_forms')}
					</RouteMenuLink>
				</div>

				<div className="flex flex-col gap-y-1">
					<TypographyP className="text-background/50 dark:text-primary/50">{t('documents')}</TypographyP>
					<RouteMenuLink to="/sysadmin/document-categories">
						<FoldersIcon className="h-4 w-4 text-background/80 dark:text-primary/60" />
						{t('document_categories')}
					</RouteMenuLink>
				</div>
			</PageSidebar>

			<div className="flex flex-1 flex-col overflow-auto">
				<Button variant="outline" className="m-4 mb-0 size-8 items-center justify-center p-0 md:hidden" onClick={() => setIsSidebarOpen((c) => !c)}>
					<MenuIcon className="size-5 text-primary" strokeWidth={1.5} />
				</Button>
				<Outlet />
			</div>
		</div>
	);
}
