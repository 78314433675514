import { Button } from '@/components/ui/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { tenantProjectsInsertInputSchema } from '@timp/server/src/schemas/tenant-projects-insert.schema';
import { useTranslation } from 'react-i18next';
import { trpc } from '@/lib/providers/trpc';
import { useNavigate } from 'react-router-dom';
import { LinkButton } from '@/components/buttons/link-button';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { Page, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';
import { SelectMultipleSuppliers } from '@/components/selects/select-multiple';

export function TenantSettingsProjectsCreatePage() {
	const tenantId = useTenantIdSafe();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { mutate, isLoading } = trpc.tenantProjectsInsert.useMutation({
		onSuccess() {
			navigate('../');
		},
	});

	const form = useForm<z.infer<typeof tenantProjectsInsertInputSchema>>({
		resolver: zodResolver(tenantProjectsInsertInputSchema),
		defaultValues: {
			supplierIds: [],
		},
	});

	return (
		<Page size="container">
			<PageToolbar>
				<PageTitle backLink>{t('project_create')}</PageTitle>
			</PageToolbar>

			<PageContent scroll>
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit((data) =>
							mutate({
								...data,
								tenantId,
							}),
						)}
						className="space-y-8"
					>
						<FormField
							control={form.control}
							name="name"
							render={({ field }) => (
								<FormItem>
									<FormLabel required>{t('project_name')}</FormLabel>
									<FormControl>
										<Input placeholder="Demo" {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="supplierIds"
							render={({ field }) => (
								<FormItem className="w-full">
									<FormLabel required={false}>{t('add_project_to_supplier')}</FormLabel>
									<SelectMultipleSuppliers
										{...field}
										values={field?.value?.map((id) => ({ id })) ?? []}
										onChange={(val) => field.onChange(val.map((v) => v.id))}
									/>
									<FormDescription>{t('add_project_to_supplier_description')}</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>
						<div className="flex flex-wrap justify-end gap-4">
							<LinkButton variant="outline" to="../">
								{t('cancel')}
							</LinkButton>

							<Button isLoading={isLoading} type="submit">
								{t('project_create')}
							</Button>
						</div>
					</form>
				</Form>
			</PageContent>
		</Page>
	);
}
