import { trpc } from '@/lib/providers/trpc';
import { useTranslation } from 'react-i18next';
import { LinkButton } from '@/components/buttons/link-button';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { DataTableLink } from '@/components/ui/data-table-link';
import { CollapsedBadges } from '@/components/badges/collapsed-badges';
import { Page, PageActions, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';
import { ServerDataTable } from '@/components/tables/server-data-table';
import { useDateFns } from '@/hooks/useDateFns';
import { MutationActionsMenu, MutationDeleteAction, MutationEditAction } from '@/components/dropdown-menus/mutation-actions-menu';
import { cn } from '@/lib/utils';
import { RelativeDate } from '@/components/ui/relative-date';

export function TenantSettingsAutomaticCasesPage() {
	const { format } = useDateFns();
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();

	const { mutate: deleteAutomaticCase } = trpc.tenantAutomaticCasesDeleteById.useMutation();

	return (
		<Page>
			<PageToolbar>
				<PageTitle>{t('automatic_cases_title')}</PageTitle>
				<PageActions>
					<LinkButton to="./create">{t('create_automatic_case')}</LinkButton>
				</PageActions>
			</PageToolbar>
			<PageContent>
				<ServerDataTable
					columnsStorageKey="tenant-settings-automatic-cases-page"
					query={trpc.tenantAutomaticCasesList}
					params={{
						tenantId,
					}}
					paginationPageSize={50}
					columns={[
						{
							id: 'title',
							title: t('case_title'),
							render(row) {
								return (
									<DataTableLink className="line-clamp-1" to={`./${row.id}/edit`}>
										{row.title}
									</DataTableLink>
								);
							},
						},
						{
							id: 'formTemplateName',
							title: t('form_template'),
							filter: true,
							enableSorting: false,
						},
						{
							id: 'suppliers',
							title: t(`suppliers`),
							filter: true,
							enableSorting: false,
							render: (row) => <CollapsedBadges variant="outline" items={row.suppliers} />,
						},
						{
							id: 'interval',
							title: t('interval'),
							render: (row) => `${row.interval.amount} ${t(row.interval.type)}`,
						},
						{
							id: 'startSendoutAt',
							title: t('start_sendout_at'),
							render: (row) => format(new Date(row.startSendoutAt), 'P'),
						},

						{
							id: 'stopSendoutAt',
							title: t('stop_sendout_at'),
							render: (row) => {
								if (!row.stopSendoutAt) return null;
								return format(new Date(row.stopSendoutAt), 'P');
							},
						},
						{
							id: 'nextEstimatedSendoutAt',
							title: t('next_sendout_at'),
							render: (row) => {
								if (!row.nextEstimatedSendoutAt) return null;
								return <RelativeDate date={row.nextEstimatedSendoutAt} />;
							},
						},
						{
							id: 'status',
							title: t('status'),
							filter: true,
							valueToLabel: (value) => t(value),
							render: (row) => {
								return <span className={cn(row.status === 'ACTIVE' && 'text-green-600', row.status === 'EXPIRED' && 'text-destructive')}>{t(row.status)}</span>;
							},
						},
						{
							id: 'id',
							title: t('actions'),
							actions: true,
							enableSorting: false,
							size: 40,
							render: (row) => (
								<MutationActionsMenu>
									<MutationEditAction to={`./${row.id}/edit`} />
									<MutationDeleteAction onConfirmDelete={() => deleteAutomaticCase({ id: row.id, tenantId })} />
								</MutationActionsMenu>
							),
						},
					]}
					searchColumns={['title']}
					defaultSortColumn="title"
				/>
			</PageContent>
		</Page>
	);
}
