import { Button } from '@/components/ui/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { tenantsInsertInputSchema } from '@timp/server/src/schemas/tenants-insert.schema';
import { useTranslation } from 'react-i18next';
import { trpc } from '@/lib/providers/trpc';
import { useNavigate } from 'react-router-dom';
import { LinkButton } from '@/components/buttons/link-button';
import { Checkbox } from '@/components/ui/checkbox';
import { Page, PageTitle, PageToolbar, PageContent } from '@/components/layouts/page';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { FileUploadInput } from '@/components/inputs/file-upload-input';
import { fileToBase64Content } from '@/lib/utils';
import { TypographySmall } from '@/components/ui/typography';
import { useUser } from '@/hooks/useUser';
import { Trash2Icon } from 'lucide-react';

export function SysAdminTenantsCreatePage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const user = useUser();

	const { mutate, isLoading } = trpc.tenantsInsert.useMutation({
		onSuccess() {
			navigate(`../`);
		},
	});

	const form = useForm<z.infer<typeof tenantsInsertInputSchema>>({
		resolver: zodResolver(tenantsInsertInputSchema),
		defaultValues: {
			name: '',
			enableAutoProcessInspections: false,
		},
	});

	return (
		<Page size="container">
			<PageToolbar>
				<PageTitle backLink>{t('create')}</PageTitle>
			</PageToolbar>
			<PageContent scroll>
				<Form {...form}>
					<form onSubmit={form.handleSubmit((data) => mutate(data))} className="space-y-8">
						<FormField
							control={form.control}
							name="name"
							render={({ field }) => (
								<FormItem>
									<FormLabel required>{t('name')}</FormLabel>
									<FormControl>
										<Input placeholder="Transport og Logistikk AS" {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="language"
							render={({ field }) => (
								<FormItem>
									<FormLabel required>{t('language')}</FormLabel>
									<Select onValueChange={field.onChange} value={field.value}>
										<FormControl>
											<SelectTrigger>
												<SelectValue />
											</SelectTrigger>
										</FormControl>
										<SelectContent>
											<SelectItem value="en">{t('language_english')}</SelectItem>
											<SelectItem value="no">{t('language_norwegian')}</SelectItem>
										</SelectContent>
									</Select>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="enableAutoProcessInspections"
							render={({ field }) => (
								<FormItem className="flex flex-col gap-y-1 space-y-0">
									<div className="flex items-center gap-x-2 space-y-0">
										<FormControl>
											<Checkbox onCheckedChange={field.onChange} name={field.name} checked={field.value} />
										</FormControl>
										<FormLabel required={false}>{t('enable_auto_process_inspections')}</FormLabel>
									</div>
									<FormDescription>{t('enable_auto_process_inspections_description')}</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						{user.isSysAdmin && (
							<FormField
								control={form.control}
								name="logo"
								render={({ field }) => (
									<FormItem className="flex flex-col justify-center">
										<FormLabel required={false}>{t('tenant_logo')}</FormLabel>
										<FormControl>
											{!field.value ? (
												<FileUploadInput
													accept={['image/png', 'image/gif', 'image/jpg', 'image/jpeg']}
													maxFileSizeMB={5}
													uploadType="image"
													onFilesSelected={async (files) => {
														if (files[0]) {
															const content = await fileToBase64Content(files[0]);
															field.onChange({
																name: files[0].name,
																type: files[0].type,
																size: files[0].size,
																content,
															});
														} else {
															field.onChange(null);
														}
													}}
												/>
											) : (
												<div className="flex flex-col gap-4">
													<img
														src={`data:${field.value.type};base64,${field.value.content}`}
														alt={t('tenant_logo')}
														className="h-auto w-full max-w-40 object-fill"
													/>
													<TypographySmall className="text-sm text-gray-500">{field.value.name}</TypographySmall>
													<div className="flex gap-2">
														<Button type="button" variant="outlineDestructive" onClick={() => field.onChange(null)}>
															<Trash2Icon className="size-4" />
															{t('remove_logo')}
														</Button>
													</div>
												</div>
											)}
										</FormControl>
										<FormDescription>{t('tenant_logo_description')}</FormDescription>
										<FormMessage />
									</FormItem>
								)}
							/>
						)}

						<div className="flex justify-end gap-4">
							<LinkButton variant="outline" to="../">
								{t('cancel')}
							</LinkButton>

							<Button isLoading={isLoading} type="submit">
								{t('tenant_create')}
							</Button>
						</div>
					</form>
				</Form>
			</PageContent>
		</Page>
	);
}
