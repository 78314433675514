import { Button } from '@/components/ui/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { systemVariablesUpdateByIdInputSchema } from '@timp/server/src/schemas/system-variables-update-by-id.schema';
import { useTranslation } from 'react-i18next';
import { trpc } from '@/lib/providers/trpc';
import { useNavigate, useParams } from 'react-router-dom';
import { LinkButton } from '@/components/buttons/link-button';
import { Textarea } from '@/components/ui/textarea';
import { Page, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';

export function SysAdminVariablesEditPage() {
	const { id } = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { mutate: mutateVariable, isLoading: variableIsLoading } = trpc.systemVariablesUpdateById.useMutation({
		onSuccess() {
			navigate('../');
		},
	});

	const { data, isLoading, error } = trpc.systemVariablesById.useQuery({ id: id! });

	const form = useForm<z.infer<typeof systemVariablesUpdateByIdInputSchema>>({
		resolver: zodResolver(systemVariablesUpdateByIdInputSchema),
		values: data,
	});

	return (
		<Page size="container" isLoading={isLoading} error={error}>
			<PageToolbar>
				<PageTitle backLink>
					{t('edit')} {data?.id}
				</PageTitle>
			</PageToolbar>

			<PageContent scroll>
				<Form {...form}>
					<form onSubmit={form.handleSubmit((data) => mutateVariable(data))} className="space-y-8">
						<FormField
							control={form.control}
							name="id"
							render={({ field }) => (
								<FormItem>
									<FormLabel required>{t('variable_name')}</FormLabel>
									<FormControl>
										<Input placeholder={t('variable_name_example')} {...field} disabled />
									</FormControl>
									<FormDescription>{t('variable_name_description', { example: t('variable_name_example') })}</FormDescription>

									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="value"
							render={({ field }) => (
								<FormItem className="w-full">
									<FormLabel required>{t('value')}</FormLabel>
									<FormControl>
										<Textarea placeholder="" {...field} rows={10} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>

						<div className="flex justify-end gap-4">
							<LinkButton variant="outline" to="../">
								{t('cancel')}
							</LinkButton>

							<Button isLoading={variableIsLoading} type="submit">
								{t('save_changes')}
							</Button>
						</div>
					</form>
				</Form>
			</PageContent>
		</Page>
	);
}
