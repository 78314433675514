import { useTenantIdSafe } from '@/hooks/useTenant';
import { TypographyBlank, TypographyH3 } from '../ui/typography';
import { ChartContentLayout } from './chart-layout';
import { trpc } from '@/lib/providers/trpc';
import { useSupplierId } from '@/hooks/useSupplier';
import { keepMaxDecimals } from '@/lib/utils';

export function TextChartCasesAnsweredWithinDeadline() {
	const tenantId = useTenantIdSafe();
	const supplierId = useSupplierId();

	const query = trpc.tenantWidgetCasesAnsweredWithinDeadline.useQuery({
		tenantId,
		supplierId,
	});

	const percentage = query?.data?.totalCases ? (query?.data?.totalAnsweredWithinDeadline / query?.data?.totalCases) * 100 || 0 : 0;

	return (
		<ChartContentLayout isLoading={query.isLoading} error={query.error}>
			<TypographyH3>{query?.data?.totalCases === 0 ? '-' : `${keepMaxDecimals(percentage, 2)}%`}</TypographyH3>
			<TypographyBlank />
		</ChartContentLayout>
	);
}
