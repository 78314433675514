import { Button } from '@/components/ui/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { tenantDepartmentsUpdateByIdInputSchema } from '@timp/server/src/schemas/tenant-departments-update-by-id.schema';
import { useTranslation } from 'react-i18next';
import { trpc } from '@/lib/providers/trpc';
import { useNavigate, useParams } from 'react-router-dom';
import { LinkButton } from '@/components/buttons/link-button';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { Page, PageTitle, PageToolbar, PageContent } from '@/components/layouts/page';
import { SelectMultipleSuppliers } from '@/components/selects/select-multiple';

export function TenantSettingsDepartmentsEditPage() {
	const navigation = useNavigate();

	const { id } = useParams();
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();

	const { mutate: mutateDepartment, isLoading: departmentIsLoading } = trpc.tenantDepartmentsUpdateById.useMutation({
		onSuccess() {
			navigation('../');
		},
	});
	const { data, isLoading, error } = trpc.tenantDepartmentsById.useQuery({
		tenantId,
		id: id!,
	});

	const form = useForm<z.infer<typeof tenantDepartmentsUpdateByIdInputSchema>>({
		resolver: zodResolver(tenantDepartmentsUpdateByIdInputSchema),
		values: data ? { ...data, supplierIds: data.suppliers.map((su) => su.id) ?? [] } : undefined,
	});
	return (
		<Page size="container" isLoading={isLoading} error={error}>
			<PageToolbar>
				<PageTitle backLink>
					{t('edit')} {data?.name}
				</PageTitle>
			</PageToolbar>

			<PageContent scroll>
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit((data) =>
							mutateDepartment({
								...data,
								tenantId,
							}),
						)}
						className="space-y-8"
					>
						<FormField
							control={form.control}
							name="name"
							render={({ field }) => (
								<FormItem>
									<FormLabel required>{t('department_name')}</FormLabel>
									<FormControl>
										<Input placeholder="Demo" {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="supplierIds"
							render={({ field }) => (
								<FormItem className="w-full">
									<FormLabel required={false}>{t('add_department_to_supplier')}</FormLabel>
									<SelectMultipleSuppliers
										{...field}
										values={field?.value?.map((id) => ({ id })) ?? []}
										onChange={(val) => field.onChange(val.map((v) => v.id))}
									/>
									<FormDescription>{t('add_department_to_supplier_description')}</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>
						<div className="flex justify-end gap-4">
							<LinkButton variant="outline" to="../">
								{t('cancel')}
							</LinkButton>

							<Button isLoading={departmentIsLoading} type="submit">
								{t('save_changes')}
							</Button>
						</div>
					</form>
				</Form>
			</PageContent>
		</Page>
	);
}
