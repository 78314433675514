import { Trans, useTranslation } from 'react-i18next';
import { RouterOutput, trpc } from '@/lib/providers/trpc';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { Page, PageContent, PageActions, PageTitle, PageToolbar } from '@/components/layouts/page';
import { useNavigate, useParams } from 'react-router-dom';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { TypographyLabel, TypographyMuted, TypographySmall } from '@/components/ui/typography';
import { CheckCircle2Icon, PencilIcon, RotateCwIcon, Trash2Icon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { toast } from 'sonner';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { useState } from 'react';
import { Spinner } from '@/components/icons/spinner';
import { RelativeDate } from '@/components/ui/relative-date';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { FormViewer } from '@/components/form-viewers/form-viewer';
import { Separator } from '@/components/ui/separator';
import { Textarea } from '@/components/ui/textarea';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { ScrollArea } from '@/components/ui/scroll-area';
import { cn } from '@/lib/utils';
import { useForm } from 'react-hook-form';
import { tenantCasesProcessInputSchema } from '@timp/server/src/schemas/tenant-cases-process.schema';
import { tenantCasesRejectInputSchema } from '@timp/server/src/schemas/tenant-cases-reject.schema';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FileActionButton } from '@/components/buttons/file-action-button';
import { DeleteDialog, MutationActionsMenu } from '@/components/dropdown-menus/mutation-actions-menu';
import { DropdownMenuItem } from '@/components/ui/dropdown-menu';
import { ChangeContactFormDialog } from '@/components/dialogs/change-contact-form-dialog';
import { CopyButton } from '@/components/buttons/copy-button';
import { CaseContactReminderStatusIconLabel } from '@/components/labels/case-contact-reminder-status-icon-label';
import { PartialFormDeviationsList } from '@/components/form-partials/partial-form-deviations-list';
import { CaseCommentSection, CaseDeviationListSection, CaseTimelimeHistorySection } from './case-details-components';
import { RenderCasePdfContent } from '@/components/pdf/generate-case-pdf-content';
import { GeneratePdf } from '@/components/pdf/generate-pdf';

interface SectionProps {
	leCase: RouterOutput['tenantCasesById'];
}

export function CaseDetailsPage() {
	const { caseId } = useParams();
	const tenantId = useTenantIdSafe();

	const navigate = useNavigate();
	const { t } = useTranslation();
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const caseQuery = trpc.tenantCasesById.useQuery({
		tenantId,
		id: caseId!,
	});

	const deleteMutation = trpc.tenantCasesDeleteById.useMutation({
		onSuccess() {
			setDeleteDialogOpen(false);
			navigate('../', {
				unstable_flushSync: true,
			});
		},
	});
	const status = caseQuery.data?.status;

	let currentTab = 'waiting_for_supplier';

	if (status === 'ANSWERED') {
		currentTab = 'ready_for_processing';
	} else if (status === 'PROCESSED') {
		currentTab = 'finished_processing';
	}

	const timelineQuery = trpc.tenantCasesTimelineByCaseId.useQuery(
		{
			caseId: caseId!,
			tenantId,
		},
		{
			refetchInterval(data) {
				const hasInvalidatedReminderWithoutUpdate = data?.some((item) => {
					if (item.type === 'EMAIL') {
						const hasLaterUpdate = data.some(
							(laterItem) => laterItem.type === 'EMAIL' && laterItem.contact.id === item.contact.id && laterItem.timestamp > item.timestamp,
						);
						return item.isInvalidated && !hasLaterUpdate;
					}
					return false;
				});
				return hasInvalidatedReminderWithoutUpdate ? 1000 : 10000;
			},
		},
	);

	return (
		<Page size="container" isLoading={caseQuery.isLoading || timelineQuery.isLoading} error={caseQuery.error || timelineQuery.error}>
			<PageToolbar>
				<PageTitle backLink>{caseQuery.data?.title ?? t('custom')}</PageTitle>
				<PageActions>
					<Button variant="outlineDestructive" onClick={() => setDeleteDialogOpen(true)}>
						<Trash2Icon className="size-4" />
						{t('delete_case')}
					</Button>

					<GeneratePdf buttonText={t('export_pdf')} fileName={`${caseQuery.data?.title}-${new Date().toISOString()}.pdf`}>
						<RenderCasePdfContent leCase={caseQuery.data!} timeline={timelineQuery.data ?? []} />
					</GeneratePdf>
				</PageActions>
			</PageToolbar>

			<DeleteDialog
				isLoading={deleteMutation.isLoading}
				name={caseQuery.data?.title}
				onConfirmDelete={() => {
					deleteMutation.mutate({ id: caseId!, tenantId });
				}}
				setOpen={setDeleteDialogOpen}
				open={deleteDialogOpen}
			/>
			<PageContent scroll>
				<Tabs value={currentTab} className="">
					<TabsList className="my-4 h-auto w-full overflow-x-auto p-2">
						<div className="flex w-full">
							<TabsTrigger
								value="waiting_for_supplier"
								className="flex basis-1/3 flex-col items-start justify-center p-3"
								disabled={currentTab !== 'waiting_for_supplier'}
							>
								<div className="flex w-full items-center justify-between">
									<span className="font-semibold uppercase">{t('step')} 1</span>
									{!(status === 'OPENED' || status === 'SENT') && <CheckCircle2Icon className="size-4 text-green-600" />}
								</div>
								<TypographyMuted>{t('waiting_for_supplier')}</TypographyMuted>
							</TabsTrigger>
							<TabsTrigger
								value="ready_for_processing"
								className="flex basis-1/3 flex-col items-start justify-center p-3"
								disabled={currentTab !== 'ready_for_processing'}
							>
								<div className="flex w-full items-center justify-between">
									<span className="font-semibold uppercase">{t('step')} 2</span>
									{status === 'PROCESSED' && <CheckCircle2Icon className="size-4 text-green-600" />}
								</div>
								<TypographyMuted>{t('ready_for_processing')}</TypographyMuted>
							</TabsTrigger>
							<TabsTrigger
								value="finished_processing"
								className="flex basis-1/3 flex-col items-start justify-center p-3"
								disabled={currentTab !== 'finished_processing'}
							>
								<div className="flex w-full items-center justify-between">
									<span className="font-semibold uppercase">{t('step')} 3</span>
									{status === 'PROCESSED' && <CheckCircle2Icon className="size-4 text-green-600" />}
								</div>
								<TypographyMuted>{t('finished_processing')}</TypographyMuted>
							</TabsTrigger>
						</div>
					</TabsList>
					<TabsContent value="waiting_for_supplier">
						<div className="flex flex-col gap-y-12 p-1">
							<LinkSection leCase={caseQuery.data!} />
							<ContactsSection leCase={caseQuery.data!} />
							<CaseTimelimeHistorySection timeline={timelineQuery.data ?? []} />
						</div>
					</TabsContent>
					<TabsContent value="ready_for_processing">
						<div className="flex flex-col gap-y-12 p-1">
							<ProcessFormSection leCase={caseQuery.data!} />
							<ContactsSection leCase={caseQuery.data!} />
							<CaseTimelimeHistorySection timeline={timelineQuery.data ?? []} />
						</div>
					</TabsContent>
					<TabsContent value="finished_processing">
						<div className="flex flex-col gap-y-12 p-1">
							<ProcessedFormSection leCase={caseQuery.data!} />
							<CaseCommentSection leCase={caseQuery.data!} />
							<CaseDeviationListSection leCase={caseQuery.data!} />
							<CaseTimelimeHistorySection timeline={timelineQuery.data ?? []} />
						</div>
					</TabsContent>
				</Tabs>
			</PageContent>
		</Page>
	);
}

function ProcessedFormSection({ leCase }: SectionProps) {
	const { t } = useTranslation();
	const [dialogOpen, setDialogOpen] = useState(false);
	return (
		<div className="flex flex-col gap-y-8 overflow-x-hidden">
			<div className="flex flex-col gap-y-4">
				<div className="flex flex-col items-center gap-x-1 md:flex-row">
					<TypographySmall>
						{leCase.processedByName ? (
							<Trans i18nKey="case_processed_by_user" values={{ name: leCase.processedByName }} />
						) : (
							<Trans i18nKey="case_processed_by_system" />
						)}
					</TypographySmall>{' '}
					<TypographyMuted>{leCase?.processedAt && <RelativeDate date={leCase.processedAt} />}</TypographyMuted>
				</div>
				<FileActionButton
					onClick={() => {
						setDialogOpen(true);
					}}
					type="button"
					filename={leCase.formTemplateName}
				>
					{t('open_form')}
				</FileActionButton>
			</div>
			<Dialog
				open={dialogOpen}
				onOpenChange={(open) => {
					setDialogOpen(open);
				}}
			>
				<DialogContent size="full">
					<DialogHeader>
						<DialogTitle className="mb-2">{leCase.formTemplateName}</DialogTitle>
						<Separator />
					</DialogHeader>
					<div className="flex flex-1 overflow-hidden">
						<FormViewer form={leCase.formContent} mode="answers-view" />
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}

function ProcessFormSection({ leCase }: SectionProps) {
	const tenantId = useTenantIdSafe();
	const [dialogOpen, setDialogOpen] = useState(false);
	const [focusedDeviation, setFocusedDeviation] = useState<string | undefined>(undefined);

	const { t } = useTranslation();

	const acceptResponseForm = useForm<z.infer<typeof tenantCasesProcessInputSchema>>({
		resolver: zodResolver(tenantCasesProcessInputSchema),
		values: {
			id: leCase.id,
			deviations: [],
			processedComment: '',
		},
	});

	const acceptResponseMutation = trpc.tenantCasesProcess.useMutation({
		onSuccess() {
			acceptResponseForm.reset();
			setDialogOpen(false);
			toast.success(t('case_process_success'));
		},
	});

	const rejectResponseForm = useForm<z.infer<typeof tenantCasesRejectInputSchema>>({
		resolver: zodResolver(tenantCasesRejectInputSchema),
		defaultValues: {
			id: leCase.id,
		},
	});
	const rejectResponseMutation = trpc.tenantCasesReject.useMutation({
		onSuccess() {
			setDialogOpen(false);
			rejectResponseForm.reset();
			toast.success(t('case_reject_success'));
		},
	});

	return (
		<>
			{/* Process dialog */}
			<Dialog
				open={dialogOpen}
				onOpenChange={(open) => {
					setDialogOpen(open);
				}}
			>
				<DialogContent size="full">
					<DialogHeader>
						<DialogTitle className="mb-2">{leCase.formTemplateName}</DialogTitle>
						<Separator />
					</DialogHeader>

					<div className="flex flex-1 gap-x-4 overflow-hidden">
						<FormViewer form={leCase.formContent} mode="answers-view" />
						<Separator orientation="vertical" />
						<div className="flex max-h-[80vh] w-1/4 flex-col overflow-y-auto lg:w-1/5">
							<Tabs defaultValue="process">
								<TabsList className="w-full">
									<TabsTrigger className="flex-1" value="process">
										{t('process')}
									</TabsTrigger>
									<TabsTrigger className="flex-1" value="send_back">
										{t('send_back_to_supplier')}
									</TabsTrigger>
								</TabsList>
								<TabsContent value="process">
									<Form {...acceptResponseForm}>
										<form
											className="flex flex-col gap-y-4"
											onSubmit={acceptResponseForm.handleSubmit((values) => {
												acceptResponseMutation.mutate({ tenantId, ...values });
											})}
										>
											<ScrollArea>
												<div className="flex flex-col gap-y-8">
													<PartialFormDeviationsList form={acceptResponseForm} setFocusedDeviation={setFocusedDeviation} focusedDeviation={focusedDeviation} />
													<FormField
														name="processedComment"
														render={({ field }) => (
															<FormItem>
																<FormLabel required>{t('comment')}</FormLabel>
																<FormControl>
																	<Textarea {...field} rows={5} />
																</FormControl>
																<FormMessage />
															</FormItem>
														)}
													/>
												</div>
											</ScrollArea>

											<Button isLoading={acceptResponseMutation.isLoading} type="submit">
												{t('process')}
											</Button>
										</form>
									</Form>
								</TabsContent>
								<TabsContent value="send_back">
									<Form {...rejectResponseForm}>
										<form
											className="flex flex-col gap-y-4"
											onSubmit={rejectResponseForm.handleSubmit((values) => {
												rejectResponseMutation.mutate({ processedComment: values.processedComment, id: leCase.id, tenantId });
											})}
										>
											<div className="flex flex-col gap-y-12">
												<FormField
													name="processedComment"
													render={({ field }) => (
														<FormItem>
															<FormLabel required={true}>{t('message_for_supplier')}</FormLabel>
															<FormControl>
																<Textarea {...field} rows={5} />
															</FormControl>
															<FormMessage />
															<FormDescription>{t('reject_case_description')}</FormDescription>
														</FormItem>
													)}
												/>
											</div>
											<Button isLoading={rejectResponseMutation.isLoading} type="submit">
												{t('send_back_to_supplier')}
											</Button>
										</form>
									</Form>
								</TabsContent>
							</Tabs>
						</div>
					</div>
				</DialogContent>
			</Dialog>
			{/* Main tab/step content */}
			<div className="flex flex-col gap-y-8 overflow-x-hidden">
				<div className="flex flex-col gap-y-4">
					<div className="flex flex-col items-center gap-x-1 md:flex-row">
						<TypographySmall>
							<Trans i18nKey="case_answered_by_supplier" values={{ name: leCase.responseCreatedByName ?? t('unknown_person') }} />
						</TypographySmall>
						<TypographyMuted>{leCase?.responseCreatedAt && <RelativeDate date={leCase.responseCreatedAt} />}</TypographyMuted>
					</div>
					<FileActionButton
						onClick={() => {
							setDialogOpen(true);
						}}
						type="button"
						filename={leCase.formTemplateName}
					>
						{t('process_case')}
					</FileActionButton>
				</div>
			</div>
		</>
	);
}

function LinkSection({ leCase }: SectionProps) {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();

	const submitLink = new URL(window.location.href).origin + `/supplier/tenant/${tenantId}/cases/${leCase.caseToken}`;

	return (
		<div className="flex flex-col overflow-x-hidden">
			<TypographyLabel className="text-lg">{t('link_to_supplier_case')}</TypographyLabel>
			<div className="flex items-center justify-between gap-x-2">
				<a className="justify-start truncate whitespace-nowrap p-0 hover:underline" href={submitLink} data-testid="supplier-case-external-link" target="_blank">
					<TypographyMuted className="truncate">{submitLink}</TypographyMuted>
				</a>
				<CopyButton text={submitLink} copyType="link" />
			</div>
		</div>
	);
}

function ContactsSection({ leCase }: SectionProps) {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();

	const resendEmail = trpc.tenantCasesResendEmail.useMutation({
		onSuccess() {
			toast.success(t('resent_email_success'));
		},
	});

	const contacts = trpc.tenantCasesContactsByCaseId.useQuery(
		{
			caseId: leCase.id,
			tenantId: tenantId,
		},
		{
			refetchInterval(data) {
				const hasEmptyStatus = data?.some((item) => item.status === null);
				return hasEmptyStatus ? 1000 : 10000;
			},
		},
	);

	const [contactIdToEdit, setContactIdToEdit] = useState<string | null>(null);

	const canResendEmails = leCase.status === 'OPENED' || leCase.status === 'SENT';

	return (
		<div className="flex flex-col overflow-x-auto">
			<div className="flex gap-x-2">
				<TypographyLabel className="text-lg">{t('supplier_contacts')}</TypographyLabel>
				{contacts.isLoading && <Spinner />}
			</div>

			{contacts.data && (
				<Table>
					<TableHeader>
						<TableRow>
							<TableHead>{t('full_name')}</TableHead>
							<TableHead>{t('email')}</TableHead>
							<TableHead>{t('phone')}</TableHead>
							<TableHead>{t('role')}</TableHead>
							{canResendEmails && <TableHead></TableHead>}
						</TableRow>
					</TableHeader>
					<TableBody>
						{contacts.data?.map((contact, index) => {
							return (
								<TableRow key={contact.id + index}>
									<TableCell>
										<div className="flex items-center gap-x-3">
											<CaseContactReminderStatusIconLabel status={contact.status} contactFullName={contact.fullName} />
											<TypographySmall className="whitespace-nowrap">{contact.fullName}</TypographySmall>
										</div>
									</TableCell>
									<TableCell>
										<a href={`mailto:${contact.email}`} className={cn('underline', contact.status === 'FAILED' && 'text-destructive')}>
											{contact.email}
										</a>
									</TableCell>
									<TableCell>
										<TypographySmall>{contact.phone}</TypographySmall>
									</TableCell>
									<TableCell>
										<TypographySmall>{contact.role}</TypographySmall>
									</TableCell>
									{canResendEmails && (
										<TableCell className="w-10">
											<MutationActionsMenu>
												<DropdownMenuItem asChild className="hover:cursor-pointer">
													<Button className="w-full justify-start gap-x-2 p-2" variant="ghost" onClick={() => setContactIdToEdit(contact.id)}>
														<PencilIcon strokeWidth={1.5} className="h-4 w-4" />
														{t('edit_contact')}
													</Button>
												</DropdownMenuItem>
												<DropdownMenuItem asChild className="hover:cursor-pointer">
													<Button
														isLoading={resendEmail.isLoading}
														className="w-full justify-start gap-x-2 p-2"
														variant="ghost"
														onClick={() => resendEmail.mutate({ caseId: leCase.id, contactId: contact.id, tenantId })}
													>
														<RotateCwIcon strokeWidth={1.5} className="h-4 w-4" />
														{t('resend_email')}
													</Button>
												</DropdownMenuItem>
											</MutationActionsMenu>
										</TableCell>
									)}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			)}
			<ChangeContactFormDialog contactId={contactIdToEdit} onClose={() => setContactIdToEdit(null)} />
		</div>
	);
}
